<div>
  <div class="modal-header">
    
    <h4 class="modal-title" >{{ title | translate }}  {{annee}}</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()">
   

      <table table class="table table-striped" *ngIf="modelesArticleInAnnee?.length > 0; else aucunModeleArticleInAnnee">
        <thead>
          <tr>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.libelle</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-facturee</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.prix-uni-ht</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.taux-tva</th>
            <th></th>
          </tr>
        </thead>
  
        <tbody>
          <tr *ngFor="let modeleArt of modelesArticleInAnnee">
            <td>{{modeleArt.libelle}}</td>
            <td>{{modeleArt.refModeCalcul.libelle}}</td>
            <td>{{modeleArt.prixUnitaire}}</td>
            <td>{{modeleArt.refTva.libelle}}</td>  
            <td>              
                <fa-icon class="clickable-icon" icon="ban" (click)="supprimerModelArticle(modeleArt)" ></fa-icon>
              
            </td>         
          </tr>
        </tbody>
      </table>

      <hr class="hr" />
      <h3 translate>page.facturation.modele.articles.title</h3>

      <table table class="table table-striped" *ngIf="modelesArticleNotInAnnee?.length > 0; else aucunModeleArticleNotInAnnee">
        <thead>
          <tr>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.libelle</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-facturee</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.prix-uni-ht</th>
            <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.taux-tva</th>
            <th>
              <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="customCheck" name="example1"
                     (change)="selectAllArticle($event)">
              <label class="custom-control-label" for="customCheck"></label>
            </div>
          </th>
          </tr>
        </thead>
  
        <tbody>
          <tr *ngFor="let modeleArt of modelesArticleNotInAnnee">
            <td>{{modeleArt.libelle}}</td>
            <td>{{modeleArt.refModeCalcul.libelle}}</td>
            <td>{{modeleArt.prixUnitaire}}</td>
            <td>{{modeleArt.refTva.libelle}}</td>      
            <td>
              <div class="custom-control custom-checkbox">
              <input [checked]="modelesArticleSelectionne.includes(modeleArt)" type="checkbox"
                     class="custom-control-input"
                     id="bloc-{{modeleArt.id}}" name="example1"
                     (change)="onCheckboxChange(modeleArt)">
              <label class="custom-control-label" for="bloc-{{modeleArt.id}}"></label>
            </div>
          </td>     
          </tr>
        </tbody>
      </table>
      <div class=" actionsButtons">
        <button type="button" class="btn btn-light" (click)="addModelArticleYear()" translate>label.ajouter</button>
      </div>

      <hr class="hr" />  

      <div class=" actionsButtons">
        <button style="position:relative;margin:1em;" type="submit" class="btn btn-primary"
          translate>page.facturation.modele.modals.article.form.enregistrer-modifications</button>
        <button type="button" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
      </div>
    </form>
  </div>
</div>

<!-- Template aucun historique -->
<ng-template #aucunModeleArticleInAnnee>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.facturation.modele.modals.article-annee.aucunModeleArticleInAnnee
  </div>
</ng-template>

<ng-template #aucunModeleArticleNotInAnnee>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.facturation.modele.modals.article-annee.aucunModeleArticleNotInAnnee
  </div>
</ng-template>
<!-- Fin template aucun historique -->
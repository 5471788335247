import {
  JsonObject,
  JsonProperty,
  CustomConverter,
  Optional,
} from "@thorolf/json-ts-mapper";
import * as moment from "moment";
import { DataIdConverterFactory } from "src/app/core/services/mapper/data-id.converter";
import { Assemblage } from "./assemblage.model";
import { DateConverter } from "src/app/core/services/mapper/converters";
import { Site } from "./site.model";
import { TypeVin } from "./type-vin.model";
import { Contenant } from "./contenant.model";

@JsonObject
export class SaisieTirage {
  @JsonProperty("codeProduit", String)
  codeProduit: string;

  @JsonProperty("codeProduitBase", String)
  codeProduitBase: string;

  @JsonProperty("numeroTirage", String)
  numeroTirage: string;

  @JsonProperty("dateTirage", String)
  @CustomConverter(DateConverter)
  dateTirage: moment.Moment;

  @JsonProperty("numeroLot", String)
  numeroLot: string;

  @JsonProperty("volumeVinBase", Number)
  volumeVinBase: number;

  @JsonProperty("volumeLiqueur", Number)
  volumeLiqueur: number;

  @JsonProperty("idSite", Number)
  @CustomConverter(DataIdConverterFactory.build(Site, "sites"))
  site: Site;

  @JsonProperty("assemblages", [Assemblage])
  @Optional
  assemblages: Assemblage[] = [];

  @JsonProperty("contenants", [Contenant])
  @Optional
  contenants: Contenant[] = [];

  @JsonProperty("idPrestataire", Number)
  @Optional
  idPrestataire: number;

  @JsonProperty("idTypeVin", Number)
  @CustomConverter(DataIdConverterFactory.build(TypeVin, "typesVin"))
  typeVin: TypeVin;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditArticleComponent } from '../../modals/edit-article/edit-article/edit-article.component';
import { from, forkJoin, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModeleFacturationService } from 'src/app/data/facturation/services/modele-facturation/modele-facturation.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ModeleArticle } from 'src/app/data/facturation/models/modele-article.model';
import { ModeleArticleService } from 'src/app/data/facturation/services/modele-article/modele-article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferencesFacturation } from 'src/app/data/facturation/models/references-facturation.model';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { ModeleFacturation } from 'src/app/data/facturation/models/modele-facturation.model';
import { RefAnneeType } from 'src/app/data/facturation/models/ref-annee-type.model';
import { find } from 'lodash';
import { RefObjetFacture } from 'src/app/data/facturation/models/ref-objet-facture.model';
import { RefTva } from 'src/app/data/facturation/models/ref-tva.model';
import { DialogService } from './changes-in-progress/dialog.service';
import { EditArticleAnneeComponent } from '../../modals/edit-article-annee/edit-article-annee.component';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-edit-facturation',
  templateUrl: './edit-facturation.component.html',
  styleUrls: ['./edit-facturation.component.scss']
})
export class EditFacturationComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  edition: boolean;
  modeleFacturation: ModeleFacturation;
  modelesArticle: ModeleArticle[];
  modelesArticleFilter: ModeleArticle[];
  refAnneeType: RefAnneeType[];
  refObjetsFactures: RefObjetFacture[];
  refQuantitesFactures;
  refTva: RefTva[];
  currentIdModeleFacturation = null;
  formSubmit = false;
  anneeFilter: number;


  mda: ModeleArticle = new ModeleArticle();

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly modeleArticleService: ModeleArticleService,
    private readonly modeleFacturationService: ModeleFacturationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly dialogService: DialogService,   
    private readonly referentielService: ReferentielService,
  ) { }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.modeleFacturation.id && !this.formSubmit) {
      this.formSubmit = false;
      return this.dialogService.confirm('Vous êtes en train de créer un modèle de facturation qui n\'est pas encore enregistré. Voulez-vous quitter la page sans enregistrer ?');
    }
    this.formSubmit = false;
    return true;
  }

  ngOnInit() {


    if (this.route.snapshot.paramMap.has('idModeleFacturation')) {
      this.currentIdModeleFacturation = parseInt(this.route.snapshot.paramMap.get('idModeleFacturation'), 10);
      isNaN(this.currentIdModeleFacturation) ? this.edition = false : this.edition = true;
    }
    this.anneeFilter = (new Date()).getFullYear();
    this.loadData(this.currentIdModeleFacturation);
  }

  getField(name: string) {
    return this.formGroup.get(name);
  }

  loadData(idModeleFacturation: number) {

    forkJoin(
      isNaN(idModeleFacturation) ? of(new ModeleFacturation()) : this.modeleFacturationService.getModeleFacturationById(SessionContext.get('idOrganisme'), idModeleFacturation),
      this.refFacturationService.getReferences(),      
      this.referentielService.getReferentiel()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([modeleFacturation, ref, references]) => {
        this.modeleFacturation = modeleFacturation;
        this.refAnneeType = ref.refAnneeTypeList;
        this.refTva = ref.refTvaList;
        this.refObjetsFactures = ref.refObjetFactureList;
        this.modelesArticle = this.modeleFacturation.modeleArticleList;
        this.refQuantitesFactures = ref.refQuantiteFactureList;
        this.createForm(modeleFacturation);

        
        this.filterItemsOfAnnee();
      }
    );
  }

  createForm(modeleFacturation: ModeleFacturation) {

    if (this.modeleFacturation.id) {
      const selectedAnneeRef = find(this.refAnneeType, refAnneeType => refAnneeType.id === modeleFacturation.refAnneeType.id);
      const selectedObjetFacture = find(this.refObjetsFactures, refObjetFacture => refObjetFacture.id === modeleFacturation.refObjetFacture.id);

      this.formGroup = this.fb.group({
        libelle: [modeleFacturation.libelle, Validators.required],
        anneeRef: [selectedAnneeRef, Validators.required],
        refObjetFacture: [selectedObjetFacture, Validators.required],
        grouperOperateur: [modeleFacturation.isGroupeOperateur, Validators.required],
        facturationDirecte: [modeleFacturation.isFacturationDirecte, Validators.required],
      });
    } else {
      this.formGroup = this.fb.group({
        libelle: [undefined, Validators.required],
        anneeRef: [undefined, Validators.required],
        refObjetFacture: [undefined, Validators.required],
        grouperOperateur: [false, Validators.required],
        facturationDirecte: [false, Validators.required]
      });
    }

    if (this.edition) {
      this.formGroup.controls.refObjetFacture.disable();
    }
  }

  submit() {
    if (this.formGroup.valid) {
      this.formSubmit = true;
      if (!this.modelesArticle || (this.modelesArticle.length === 0)) {
        this.formSubmit = false;
        this.modalsService.error(
          this.translate.instant(`page.facturation.edit.alert.modalError.modalErrorText`),
          this.translate.instant(`page.facturation.edit.alert.modalError.modalErrorTitle`), {
          cancelText: this.translate.instant(`label.annuler`)
        }
        ).then();
      } else {
        const modeleFacturation = new ModeleFacturation();

        const libelle = this.getField('libelle').value;
        const anneeRef = this.getField('anneeRef').value;
        const refObjetFacture = this.getField('refObjetFacture').value;
        const grouperOperateur = this.getField('grouperOperateur').value;
        const facturationDirecte = this.getField('facturationDirecte').value;

        modeleFacturation.libelle = libelle;
        modeleFacturation.refObjetFacture = refObjetFacture;
        modeleFacturation.refAnneeType = anneeRef;
        modeleFacturation.isSupprime = false;
        modeleFacturation.isGroupeOperateur = grouperOperateur;
        modeleFacturation.isFacturationDirecte = facturationDirecte;
        modeleFacturation.isUsite = this.modeleFacturation.isUsite ? this.modeleFacturation.isUsite : false;
        modeleFacturation.modeleArticleList = this.modelesArticle;
        modeleFacturation.idDomaine = SessionContext.get('idDomaine');
        modeleFacturation.idOrganisme = SessionContext.get('idOrganisme');


        if (this.edition) {
          if (modeleFacturation.isUsite) {
            this.modalsService.alert(`Les facturations déjà génèrées ne seront pas modifiées.`);
          }
          modeleFacturation.id = this.modeleFacturation.id;
          this.modeleFacturationService.modifyModeleFacturation(SessionContext.get('idOrganisme'), modeleFacturation).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.facturation.edit.alert.modele_facturation.modification.message', { libelle: response.libelle }),
                this.translate.instant('page.facturation.edit.alert.modele_facturation.modification.title'),
                { timeOut: 10 * 1000 }
              );
              this.router.navigate(['../..'], { relativeTo: this.route });
            }
          );
        } else {
          this.modeleFacturationService.createModeleFacturation(SessionContext.get('idOrganisme'), modeleFacturation).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.facturation.edit.alert.modele_facturation.creation.message', { libelle: response.libelle }),
                this.translate.instant('page.facturation.edit.alert.modele_facturation.creation.title'),
                { timeOut: 10 * 1000 }
              );
              this.router.navigate(['../..'], { relativeTo: this.route });
            }
          );
        }
      }
    } else {
      console.warn(this.formGroup);
    }
  }


  getRefTva(id: any): RefTva {
    const idToString: number = parseInt(id.toString());
    return this.refTva.find(r => r.id === idToString);
  }

  addModeleArticle() {
    const modalRef = this.modalService.open(EditArticleComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idModeleFacturation = this.currentIdModeleFacturation;
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.edition = this.edition;
    modalRef.componentInstance.selectedObjetFacture = this.getField('refObjetFacture').value;
    modalRef.componentInstance.modeleArticle = new ModeleArticle();
    modalRef.componentInstance.modeleFacturation = this.modeleFacturation;
    modalRef.result.then((result) => {
      this.modelesArticle = this.modelesArticle ? this.modelesArticle : [];
      this.modelesArticle.push(result);
      if (this.modelesArticle.length > 0) {
        this.formGroup.controls.refObjetFacture.disable();
      }

      if (this.currentIdModeleFacturation) {
        this.loadData(this.currentIdModeleFacturation);
      }
    });
  }


  editModeleArticle(modeleArticle: ModeleArticle) {
    const modalRef = this.modalService.open(EditArticleComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idModeleFacturation = this.currentIdModeleFacturation;
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.edition = this.edition;
    modalRef.componentInstance.selectedObjetFacture = this.getField('refObjetFacture').value;
    modalRef.componentInstance.modeleArticle = modeleArticle;
    modalRef.componentInstance.modeleFacturation = this.modeleFacturation;
    modalRef.result.then((result) => {
      if (modeleArticle.id) {
        this.loadData(this.currentIdModeleFacturation);
      } else {
        this.modelesArticle = this.modelesArticle ? this.modelesArticle : [];
        const index: number = this.modelesArticle.indexOf(modeleArticle);
        this.modelesArticle[index] = result;
      }
    });
  }

  deleteModeleArticle(modeleArticle: ModeleArticle) {
    this.modalsService.confirm(
      `${this.translate.instant('page.facturation.edit.alert.modele_article.confirm_remove.message')} ${modeleArticle.libelle} ?`,
      this.translate.instant('page.facturation.edit.alert.modele_article.confirm_remove.title'),
      { cancelText: this.translate.instant(`label.annuler`), submitText: this.translate.instant(`label.valider`) }
    ).then(() => {


      if (modeleArticle.id) {
        this.modeleArticleService.deleteModeleArticle(SessionContext.get('idOrganisme'), modeleArticle, this.currentIdModeleFacturation)
          .subscribe(() => {
            this.toastr.success(
              this.translate.instant('page.facturation.edit.alert.modele_article.suppression.message', { libelle: modeleArticle.libelle }),
              this.translate.instant('page.facturation.edit.alert.modele_article.suppression.title'),
              { timeOut: 10 * 1000 }
            );
            this.loadData(this.modeleFacturation.id);
          });
      } else {
        const index: number = this.modelesArticle.indexOf(modeleArticle);
        if (index !== -1) {
          this.modelesArticle.splice(index, 1);
        }
        if (this.modelesArticle.length > 0) {
          this.formGroup.controls.refObjetFacture.disable();
        } else {
          this.formGroup.controls.refObjetFacture.enable();
        }
      }
    });
  }

  filterItemsOfAnnee(){    
    this.modelesArticleFilter = this.modelesArticle.filter(x => x.campagnes.some(c => c.annee === this.anneeFilter) );
  }

  updateModeleArticleFilter(){
    const modalRef = this.modalService.open(EditArticleAnneeComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.idModeleFacturation = this.currentIdModeleFacturation;
    modalRef.componentInstance.idOrganisme = SessionContext.get('idOrganisme');
    modalRef.componentInstance.annee = this.anneeFilter;
    modalRef.result.then((result) => {
       
         this.loadData(this.currentIdModeleFacturation);

    });
  }

}

import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, of } from "rxjs";
import { Contact } from "src/app/data/intervenant/models/contact.model";
import { ContactsService } from "src/app/data/intervenant/services/contacts/contacts.service";
import { Fonction } from "src/app/data/intervenant/models/fonction.model";
import { ReferencesService } from "src/app/data/intervenant/services/references/references.service";
import { Referent } from "src/app/data/intervenant/models/referent.model";
import { Groupe } from "src/app/data/intervenant/models/groupe.models";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { EntrepriseBase } from "src/app/data/intervenant/models/entreprise-base.model";
import { Commune } from "src/app/shared/models/commune.model";
import { CommuneService } from "src/app/shared/services/dep-commune/commune.service";

@Component({
  selector: "app-edit-contact",
  templateUrl: "./edit-contact.component.html",
  styleUrls: ["./edit-contact.component.scss"],
})
export class EditContactComponent implements OnInit {
  formGroup: FormGroup;

  creation = false;

  title: string;

  idEntreprise: any;

  contact: Contact;
  fonctions: Fonction[];
  referents: Referent[];
  groupes: Groupe[];
  entreprises: EntrepriseBase[];

  communes: Commune[];

  get civilite() {
    return this.formGroup.get("civilite");
  }
  get nom() {
    return this.formGroup.get("nom");
  }
  get prenom() {
    return this.formGroup.get("prenom");
  }
  get mail() {
    return this.formGroup.get("mail");
  }
  get adresse() {
    return this.formGroup.get("adresse");
  }
  get commune() {
    return this.formGroup.get("commune");
  }
  get codepostal() {
    return this.formGroup.get("codepostal");
  }
  get telephoneportable() {
    return this.formGroup.get("telephoneportable");
  }
  get telephonefixe() {
    return this.formGroup.get("telephonefixe");
  }
  get fonction() {
    return this.formGroup.get("fonction");
  }
  get referent() {
    return this.formGroup.get("referents");
  }
  get groupe() {
    return this.formGroup.get("groupes");
  }
  get estintervenant() {
    return this.formGroup.get("estintervenant");
  }
  get entreprise() {
    return this.formGroup.get("entreprise");
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly contactsService: ContactsService,
    private readonly entrepriseService: EntreprisesService,
    private readonly communeService: CommuneService
  ) {}

  ngOnInit() {
    const idc = parseInt(this.route.snapshot.paramMap.get("idc"), 10);
    const id = parseInt(this.route.snapshot.paramMap.get("id"), 10);

    if (!idc) {
      this.creation = true;
    }

    if (id) {
      this.idEntreprise = id;
    }

    this.loadData(idc);
  }

  loadData(id: number) {
    this.title = this.creation
      ? "page.contacts.form.title.add"
      : "page.contacts.form.title.edit";

    forkJoin(
      !this.creation ? this.contactsService.getContact(+id) : of(new Contact()),
      this.contactsService.getReferents(),
      this.contactsService.getGroupes(),
      this.entrepriseService.getEntreprisesBase()
    )
      .pipe(this.loaderService.operator())
      .subscribe(([contact, referents, groupes, entreprises]) => {
        this.fonctions = ReferencesService.getReference("fonctions");

        this.contact = contact;

        this.referents = referents;
        this.groupes = groupes;
        this.entreprises = entreprises;

        if (this.creation) {
          this.initForm(contact.id ? contact : null);
        } else {
          forkJoin(this.communeService.getCommunes(contact.codepostal))
            .pipe(this.loaderService.operator())
            .subscribe(([communes]) => {
              this.communes = communes;
              this.initForm(contact.id ? contact : null);
            });
        }
      });
  }

  private initForm(contact?: Contact): void {
    this.formGroup = this.fb.group({
      nom: [
        { value: contact ? contact.nom : undefined, disabled: false },
        Validators.required,
      ],
      prenom: [
        { value: contact ? contact.prenom : undefined, disabled: false },
        Validators.required,
      ],
      civilite: [
        { value: contact ? contact.civilite : undefined, disabled: false },
        Validators.required,
      ],
      mail: [
        { value: contact ? contact.mail : undefined, disabled: false },
        { validators: [Validators.email, Validators.required] },
      ],
      telephoneportable: [
        {
          value: contact ? contact.telephoneportable : undefined,
          disabled: false,
        },
        Validators.pattern(/^([+][0-9]{2}|[0-9]{1})[0-9]{9}$/),
      ],
      telephonefixe: [
        { value: contact ? contact.telephonefixe : undefined, disabled: false },
        Validators.pattern(/^(?:[+])?[0123456789]{10}$/),
      ],
      adresse: [
        { value: contact ? contact.adresse : undefined, disabled: false },
        Validators.required,
      ],
      codepostal: [
        { value: contact ? contact.codepostal : undefined, disabled: false },
        Validators.required,
      ],
      commune: [
        {
          value: contact
            ? this.communes.find((c) => c.nom === contact.commune)
            : undefined,
          disabled: false,
        },
        Validators.required,
      ],
      fonction: [
        {
          value:
            contact && contact.fonction
              ? this.fonctions.find((fct) => contact.fonction.id === fct.id)
              : undefined,
          disabled: false,
        },
      ],
      referents: [
        contact && contact.referents
          ? this.referents.filter((ref) =>
              contact.referents.find((r) => r.id === ref.id)
            )
          : undefined,
      ],
      groupes: [
        contact && contact.groupes
          ? this.groupes.filter((ref) =>
              contact.groupes.find((r) => r.id === ref.id)
            )
          : undefined,
      ],
      estintervenant: [
        {
          value: contact && contact.estintervenant ? true : false,
          disabled: false,
        },
      ],
      entreprise: [
        {
          value: contact
            ? this.entreprises.find((ent) => contact.entreprise.id === ent.id)
            : this.idEntreprise
            ? this.entreprises.find((ent) => this.idEntreprise === ent.id)
            : undefined,
          disabled: false,
        },
        Validators.required,
      ],
    });
  }

  submit() {
    if (this.formGroup.valid && this.communes.length > 0) {
      this.contact.mail = this.mail.value;
      this.contact.nom = this.nom.value;
      this.contact.prenom = this.prenom.value;
      this.contact.telephoneportable = this.telephoneportable.value;
      this.contact.telephonefixe = this.telephonefixe.value;
      this.contact.civilite = this.civilite.value;
      this.contact.adresse = this.adresse.value;
      this.contact.codepostal = this.codepostal.value;
      this.contact.commune = this.commune.value.nom;
      this.contact.entreprise = this.entreprise.value;
      if (this.fonction.value !== undefined)
        this.contact.fonction = this.fonction.value;
      else this.contact.fonction = null;
      this.contact.groupes = this.groupe.value;
      this.contact.referents = this.referent.value;
      this.contact.estintervenant = this.estintervenant.value;

      if (this.contact.id == null) {
        this.contactsService
          .creerContact(this.contact)
          .pipe(this.loaderService.operator())
          .subscribe((idContact) => {
            this.router.navigate(["."], { relativeTo: this.route.parent });
          });
      } else {
        this.contactsService
          .modifierContact(this.contact)
          .pipe(this.loaderService.operator())
          .subscribe(() => {
            this.router.navigate(["."], { relativeTo: this.route.parent });
          });
      }
    }
  }

  onCancel() {
    if (this.idEntreprise) {
      this.router.navigate(
        ["/main/admin/params/entreprises/fiche/" + this.idEntreprise],
        { relativeTo: this.route.parent }
      );
    } else
      this.router.navigate(["/main/admin/params/contacts"], {
        relativeTo: this.route.parent,
      });
  }

  changeValueCodePostal(event): void {
    const codePostal = event.target.value;
    if (codePostal.length === 5) {
      this.communeService
        .getCommunes(codePostal)
        .pipe(this.loaderService.operator())
        .subscribe((communes) => {
          this.communes = communes;
        });
    } else this.communes = [];

    this.formGroup.patchValue({ commune: undefined });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

import { OperateurSitesService } from 'src/app/data/declaration/services/operateur-sites/operateur-sites.service';
import { StockProduitService } from 'src/app/data/declaration/services/stock-produit/stock-produit.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { MouvementsProduitsService } from 'src/app/data/declaration/services/mouvements/mouvements-produits.service';
import { Produit } from 'src/app/data/habilitation/models/produit.model';
import { SessionContext, NavigationContext } from 'src/app/core/services/config/app.settings';
import { UtilisateurTypeCode } from 'src/app/data/intervenant/models/enums/type-utilisateur.enum';
import { Site } from 'src/app/data/declaration/models/site.model';
import { StockProduit } from 'src/app/data/declaration/models/stock-produit.model';
import { Cepage } from 'src/app/data/declaration/models/cepage.model';
import { TypeCahierCode } from 'src/app/data/habilitation/models/enums/type-cahier-code.enum';
import { SaisieTransaction } from 'src/app/data/declaration/models/saisie-transaction.model';
import { Assemblage } from 'src/app/data/declaration/models/assemblage.model';
import { RechercherAcheteurComponent } from '../rechercher-acheteur/rechercher-acheteur.component';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { AcheteurExport } from 'src/app/data/declaration/models/acheteur-export.model';

interface Acheteur { acheteurFrance?: Operateur; acheteurExport?: AcheteurExport; }

@Component({
  selector: 'app-saisir-transaction',
  templateUrl: './saisir-transaction.component.html',
  styleUrls: ['./saisir-transaction.component.scss']
})
export class SaisirTransactionComponent implements OnInit {

  @Input()
  produit: Produit = new Produit();
  @Input()
  campagne: string;
  @Input()
  typeCahier: TypeCahierCode;

  idOperateur: number;

  sitesOperateur: Site[] = [];
  stocksProduit: StockProduit[] = [];
  stockCourant: StockProduit;
  cepages: Cepage[];

  get raisonSocialeAcheteur() {
    if (this.formGroup.get('acheteur').value) {
      if (this.formGroup.get('acheteur').value.acheteurExport) {
        return this.formGroup.get('acheteur').value.acheteurExport.raisonSociale;
      }
      if (this.formGroup.get('acheteur').value.acheteurFrance) {
        return this.formGroup.get('acheteur').value.acheteurFrance.raisonSociale || this.formGroup.get('acheteur').value.acheteurFrance.email;
      }
    }
    return '';
  }
  get volumeTotalValue() {
    return this.getField('volume').value + this.getAssemblages().controls.reduce((total, assemblage: FormGroup) => total + assemblage.get('volume').value, 0);
  }

  formGroup: FormGroup;
  loaded = false;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly stockProduitService: StockProduitService,
    private readonly referentielService: ReferentielService,
    private readonly translate: TranslateService,
    private readonly loader: AdvBootstrapLoaderService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit() {

    this.idOperateur = SessionContext.get('utilisateurType') === UtilisateurTypeCode.OPERATEUR ?
      SessionContext.get('idIntervenant') :
      NavigationContext.get('idOperateur');

    this.formGroup = this.createForm();
    this.loadData();
  }

  loadData() {
    forkJoin(
      this.stockProduitService.getStockByProductCodeAndMoveType(this.idOperateur, this.produit.code, 'VRAC'),
      this.referentielService.getReferentiel(),
      this.operateurSitesService.getSitesOperateur(this.idOperateur)
    ).pipe(
      this.loader.operator()
    ).subscribe(([stocks, ref, sites]) => {
      stocks.forEach(stock => {
        if (stock.annee.toString() === this.campagne) {
          this.stockCourant = stock;
        } else {
          this.stocksProduit.push(stock);
        }
      });

      this.sitesOperateur = sites;
      this.cepages = ref.cepages;

      this.loaded = true;
    });
  }

  // #######################################
  // ##########  manage forms  #############
  // #######################################

  createForm() {
    return this.fb.group({
      produit: [{ value: this.produit.libelle, disabled: true }],
      date: [undefined, Validators.required],
      logement: [undefined, Validators.required],
      lot: [undefined, Validators.required],
      contrat: [undefined, Validators.required],
      cepages: [undefined],
      volume: [undefined, [
        Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
        this.positifStrictValidator,
        this.volumeLteStockValidatorFactory('courant')
      ]],
      assemblages: this.fb.array([]),
      entreposage: [undefined, Validators.required],
      observation: [undefined],
      acheteur: [undefined, Validators.required]
    });
  }

  createFormAssemblage() {
    return this.fb.group({
      annee: [undefined, [this.uniqueValueInFormArrayValidatorFactory(this.getAssemblages(), 'annee'), Validators.required]],
      volume: [undefined, [
        Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
        this.positifStrictValidator,
        this.volumeLteStockValidatorFactory('assemblage'),
        Validators.required
      ]]
    });
  }

  addAssemblage() {
    this.getAssemblages().push(this.createFormAssemblage());
  }

  removeAssemblage(index: number) {
    this.getAssemblages().removeAt(index);
  }


  getAssemblages() {
    return this.formGroup.get('assemblages') as FormArray;
  }

  getAssemblage(index: number) {
    return this.formGroup.get('assemblages.' + index) as FormGroup;
  }

  getField(key: string) {
    return this.formGroup.get(key);
  }

  get displayAlertMillesime() {
    return this.getField('volume').value < this.volumeTotalValue * 0.85;
  }

  // #######################################
  // ##########   acheteur   #############
  // #######################################

  rechercherAcheteur() {
    const modal = this.modalService.open(RechercherAcheteurComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.idOperateur = this.idOperateur;

    modal.result.then((acheteur) => {
      this.formGroup.get('acheteur').setValue(acheteur);
    }, () => { });
  }

  // #######################################
  // ############   modal   ################
  // #######################################


  close() { this.modal.dismiss(); }

  submit() {
    if (this.formGroup.valid) {
      const date = this.getField('date').value as NgbDateStruct;

      const saisie = new SaisieTransaction();
      saisie.codeProduit = this.produit.code;
      saisie.date = moment([date.year, date.month - 1, date.day, 0, 0, 0]);
      saisie.logement = this.getField('logement').value;
      saisie.numeroLot = this.getField('lot').value;
      saisie.numeroContrat = this.getField('contrat').value;
      saisie.volume = this.volumeTotalValue;
      saisie.site = this.getField('entreposage').value;

      const acheteur = this.getField('acheteur').value as Acheteur;
      if (acheteur.acheteurExport) {
        saisie.idAcheteurExport = acheteur.acheteurExport.id;
      }
      if (acheteur.acheteurFrance) {
        saisie.idAcheteurFrance = acheteur.acheteurFrance.id;
      }

      // assemblages
      saisie.assemblages = [Object.assign(new Assemblage(), { annee: parseInt(this.campagne, 10), volume: this.getField('volume').value })];
      this.getAssemblages().controls.forEach(assemblage => {
        const saisieAssemblage = new Assemblage();
        saisieAssemblage.annee = assemblage.get('annee').value.annee;
        saisieAssemblage.volume = assemblage.get('volume').value;

        saisie.assemblages.push(saisieAssemblage);
      });

      // IGP
      saisie.cepages = this.getField('cepages').value;
      saisie.observation = this.getField('observation').value;

      this.mouvementsProduitsService.creerTransaction(this.idOperateur, this.campagne, saisie).subscribe(() => {
        this.translate.get('page.declarations.synthese.modal.saisir-transaction.creation-ok').subscribe(msg => {
          this.toastr.success('', msg);
        });
        this.modal.close();
      });
    }
  }

  // #######################################
  // ##########   validators   #############
  // #######################################

  positifStrictValidator(control: FormControl) {
    return (control.value <= 0) ? { invalid: true } : null;
  }

  volumeLteStockValidatorFactory(stockType: 'courant' | 'assemblage') {
    return (control: FormControl) => {
      let stock = null;
      try {
        if (stockType === 'courant') {
          stock = this.stockCourant.stock;
        } else {
          stock = control.parent.get('annee').value.stock;
        }
      } catch (e) {
        stock = 0;
      }
      return (this.typeCahier !== 'IGP' && (control.value > stock)) ? { invalid: true } : null;
    };
  }

  assemblageVolumeValidator(control: FormControl) {
    if (control.value && control.parent && control.value > control.parent.get('annee').value.stock) {
      return { invalid: true };
    }

    return null;
  }

  uniqueValueInFormArrayValidatorFactory(formArray: FormArray, fieldKey: string) {
    return (control: FormControl) => {
      formArray.controls.forEach((group: FormGroup) => {
        const ct = group.get(fieldKey);
        if (ct !== control && ct.value === control.value) {
          return { invalid: true };
        }
      });

      return null;
    };
  }
}

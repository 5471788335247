import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Observable, forkJoin } from 'rxjs';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { FormBuilder, NgForm, FormGroup, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { ModeleArticle } from 'src/app/data/facturation/models/modele-article.model';
import { ModeleArticleService } from 'src/app/data/facturation/services/modele-article/modele-article.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { Router, ActivatedRoute } from '@angular/router';
import { RefModeCalcul } from 'src/app/data/facturation/models/ref-mode-calcul.model';
import { RefQuantiteFacture } from 'src/app/data/facturation/models/ref-quantite-facture.model';
import { RefTva } from 'src/app/data/facturation/models/ref-tva.model';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import { RefObjetFacture } from 'src/app/data/facturation/models/ref-objet-facture.model';
import { find } from 'lodash';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { CacheService } from 'src/app/core/services/cache/cache.service';
import { ModeleFacturation } from 'src/app/data/facturation/models/modele-facturation.model';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { ReferenceQuantiteEnum } from 'src/app/data/facturation/models/enums/references-quantite.enum';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss']
})
export class EditArticleComponent extends AbstractRechercheItemComponent<ModeleArticle> implements OnInit {
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  @Input() idModeleFacturation: number;
  @Input() idOrganisme: number;
  @Input() modeleArticle: ModeleArticle;
  @Input() edition: boolean;
  @Input() selectedObjetFacture: any;
  @Input() modeleFacturation: ModeleFacturation;
  refQuantitesFacture: RefQuantiteFacture[];
  refQuantitesFactureFilter: RefQuantiteFacture[];
  public refAnnees: Campagne[] = [];

  refModeCalcul: RefModeCalcul[];
  refObjetsFactures: RefObjetFacture[];
  refTva: RefTva[];
  cahiers: Cahier[];
  loaded = false;

  get annees() {
    return this.formGroup.get('annees') as FormArray;
  }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly modeleArticleService: ModeleArticleService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly cahiersService: CahiersService,
    private readonly modalsService: AdvBootstrapModalService,    
    private readonly referentielService: ReferentielService,
  ) {
    super(modal);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    forkJoin(
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences(),      
      this.referentielService.getReferentiel()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([cahiers, ref, referentiel]) => {
        this.cahiers = cahiers;
        this.refModeCalcul = ref.refModeCalculList;
        this.refObjetsFactures = ref.refObjetFactureList;
        this.refQuantitesFacture = ref.refQuantiteFactureList;
        this.refTva = ref.refTvaList;
        this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id);
        this.createForm(this.modeleArticle);        
        this.refAnnees = referentiel.campagnes;
      }
    );
  }


  createForm(modeleArticle: ModeleArticle) {
    let selectedModeCalcul;
    let selectedTva;
    let selectedQuantiteFacture;
    if (modeleArticle.id) {
      selectedModeCalcul = find(this.refModeCalcul, refModeCalcul => refModeCalcul.id === modeleArticle.refModeCalcul.id);
      selectedTva = find(this.refTva, refTva => refTva.id === modeleArticle.refTva.id);
      selectedQuantiteFacture = find(this.refQuantitesFacture, refQuantiteFacture => refQuantiteFacture.id === modeleArticle.refQuantiteFacture.id);
      this.formGroup = this.fb.group({
        libelle: [this.modeleArticle.libelle, Validators.required],
        prixUnitaireHT: [this.modeleArticle.prixUnitaire, Validators.required],
        quantiteMinimum: [this.modeleArticle.quantiteMinimum, Validators.required],
        modeCalcul: [selectedModeCalcul, Validators.required],
        typeQteFacture: [selectedQuantiteFacture, Validators.required],
        cdc: [this.modeleArticle.cahiers, Validators.required],
        tauxTVA: [selectedTva, Validators.required],
        annees: this.fb.array([]),
        
      });

      if (modeleArticle.campagnes && modeleArticle.campagnes.length > 0) {
        modeleArticle.campagnes.forEach(campagne => this.onAddYear(campagne.annee));
      }
    } else {
      this.formGroup = this.fb.group({

        libelle: [this.modeleArticle.libelle ? this.modeleArticle.libelle : undefined, Validators.required],
        prixUnitaireHT: [this.modeleArticle.prixUnitaire ? this.modeleArticle.prixUnitaire : undefined, Validators.required],
        quantiteMinimum: [this.modeleArticle.quantiteMinimum ? this.modeleArticle.quantiteMinimum : undefined, Validators.required],
        modeCalcul: [this.modeleArticle.refModeCalcul ? find(this.refModeCalcul, refModeCalcul => refModeCalcul.id === modeleArticle.refModeCalcul.id) : undefined, Validators.required],
        typeQteFacture: [this.modeleArticle.refQuantiteFacture ? find(this.refQuantitesFacture, refQuantiteFacture => refQuantiteFacture.id === modeleArticle.refQuantiteFacture.id) : undefined, Validators.required],
        cdc: [this.modeleArticle.cahiers, Validators.required],
        tauxTVA: [this.modeleArticle.refTva ? find(this.refTva, refTva => refTva.id === modeleArticle.refTva.id) : this.refTva[0], Validators.required],
        annees: this.fb.array([]),
      });
    }

    
    this.loaded = true;
  }

  submit() {
    if (this.formGroup.valid) {
      const modeleArticle = new ModeleArticle();
      const libelle = this.getField('libelle').value;
      const prixUnitaireHT = this.getField('prixUnitaireHT').value;
      const quantiteMinimum = this.getField('quantiteMinimum').value;
      const modeCalcul = this.getField('modeCalcul').value;
      const typeQteFacture = this.getField('typeQteFacture').value;
      const cdc = this.getField('cdc').value;
      const tauxTVA = this.getField('tauxTVA').value;

      // Récupérer les campagnes
      const campagnes: Campagne[] = [];
      this.annees.controls.forEach(campagne => {
        const refCampagne = this.refAnnees.find(refAnnee => refAnnee.annee === campagne.get('annee').value);
        if (refCampagne) {
          campagnes.push(refCampagne);
        }
      });

      modeleArticle.libelle = libelle;
      modeleArticle.refModeCalcul = modeCalcul;
      modeleArticle.refQuantiteFacture = typeQteFacture;
      modeleArticle.refTva = tauxTVA;
      modeleArticle.cahiers = cdc;
      modeleArticle.prixUnitaire = parseFloat(prixUnitaireHT);
      modeleArticle.quantiteMinimum = parseFloat(quantiteMinimum);
      modeleArticle.campagnes =  campagnes;

      // si l'article existe donc édition
      if (this.modeleArticle.id) {
        modeleArticle.id = this.modeleArticle.id;
        this.modeleArticleService.modifyModeleArticle(SessionContext.get('idOrganisme'), modeleArticle, this.idModeleFacturation).subscribe(
          response => {
            this.toastr.success(
              this.translate.instant('page.facturation.edit.alert.modele_article.modification.message', { libelle: response.libelle }),
              this.translate.instant('page.facturation.edit.alert.modele_article.modification.title'),
              { timeOut: 10 * 1000 }
            );
            this.modal.close(modeleArticle);
            this.openModalInformationsUtilisateurs();
          }
        );
      } else { // sinon, création
        if (this.edition) {
          this.modeleArticleService.createModeleArticle(SessionContext.get('idOrganisme'), modeleArticle, this.idModeleFacturation).subscribe(
            response => {
              this.toastr.success(
                this.translate.instant('page.facturation.edit.alert.modele_article.creation.message', { libelle: response.libelle }),
                this.translate.instant('page.facturation.edit.alert.modele_article.creation.title'),
                { timeOut: 10 * 1000 }
              );
              this.modal.close(modeleArticle);
              this.openModalInformationsUtilisateurs();
            }
          );
        } else {
          this.modal.close(modeleArticle);
        }
      }
    }
  }

  private openModalInformationsUtilisateurs() {
    if (this.modeleFacturation.isUsite) {
      this.modalsService.alert(`Les facturations déjà génèrées ne seront pas modifiées.`);
    }
  }

  changeModeCalcul() {
    if (this.getField('modeCalcul').value.code === 'FORFAITAIRE') {
      this.formGroup.controls.quantiteMinimum.setValue('0');
      this.formGroup.controls.quantiteMinimum.disable();
      this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id
        && qte.code.split('_')[0] === ReferenceQuantiteEnum.FORFAIT_SPLIT);
      this.formGroup.controls.typeQteFacture.setValue(this.refQuantitesFactureFilter[0]);
      this.formGroup.controls.typeQteFacture.updateValueAndValidity();
    } else {
      this.formGroup.controls.quantiteMinimum.enable();
      this.refQuantitesFactureFilter = this.refQuantitesFacture.filter(qte => qte.refObjetFacture.id === this.selectedObjetFacture.id
        && qte.code.split('_')[0] != ReferenceQuantiteEnum.FORFAIT_SPLIT);
      this.formGroup.controls.typeQteFacture.setValue(this.refQuantitesFactureFilter[0]);
      this.formGroup.controls.typeQteFacture.updateValueAndValidity();
    }
  }

  getData(): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  getField(name: string) {
    return this.formGroup.get(name);
  }

  /**
   * Ajoute une année dans le formulaire
   * @param annee L'annnée à ajouter
   */
  public onAddYear(annee: number | undefined): void {

    this.annees.push(this.fb.group({
      annee: [annee, [
        Validators.required,
        Validators.pattern(/^\d{4}/),
        this.yearIsCampaignValidator.bind(this)
      ]
      ]
    }));
  }

  /**
   * Retire une année du formulaire
   * @param index L'indice de l'année à retirer
   */
  public onDeleteYear(index: number): void {
    this.annees.removeAt(index);
  }

  /**
   * Retourne toutes les AbstractControl des années
   * @returns Une collection de AbstractControl
   * @see AbstractControl
   */
  public getYears(): AbstractControl[] {
    return this.annees.controls;
  }

  /**
   * Vérifie qu'une année saisie par l'utilisateur est gérée dans la liste
   * des campagnes du référentiel
   * @param control Le contrôle du formulaire à vérifier
   */
  private yearIsCampaignValidator(control: FormControl) {
    return (this.refAnnees.find(campagne => campagne.annee === control.value)) ?
      null : {invalid: true};
  }

}

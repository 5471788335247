import { JsonObject, JsonProperty, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class Produit {
  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('code', String)
  code: string = undefined;

  @JsonProperty('libelle', String)
  libelle: string = undefined;

  @JsonProperty('idCahier', Number)
  @Optional
  idCahier: number;

  @JsonProperty('libelleCahier', String)
  @Optional
  libelleCahier: string;

  @JsonProperty('eligibleVCI', Boolean)
  @Optional
  eligibleVci = false;

  @JsonProperty('eligibleVSI', Boolean)
  @Optional
  eligibleVsi = false;

  @JsonProperty('vinBase', Boolean)
  @Optional
  vinBase = false;
}

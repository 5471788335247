import { JsonObject, JsonProperty, Optional } from "@thorolf/json-ts-mapper";
import { DeclarationMouvementEdition } from "./declaration-mouvement-edition.model";
import { DeclarationSiteEdition } from "./declaration-site-edition.model";
import { DeclarationOperateurEdition } from "./declaration-operateur-edition.model";
import { OrganismeEdition } from "./organisme-edition.model";

@JsonObject
export class DeclarationEdition {

    @JsonProperty('numeroDossier', String)
    @Optional
    numeroDossier: string;

    @JsonProperty('date', String)
    @Optional
    date: string;

    @JsonProperty('cahier', String)
    @Optional
    cahier: string;

    @JsonProperty('email', String)
    @Optional
    email: string;
    
    @JsonProperty('lots', [DeclarationMouvementEdition])
    @Optional
    lots: DeclarationMouvementEdition[];

    @JsonProperty('lotsNonControlables', [DeclarationMouvementEdition])
    @Optional
    lotsNonControlables: DeclarationMouvementEdition[];

    @JsonProperty('lotsControlables', [DeclarationMouvementEdition])
    @Optional
    lotsControlables: DeclarationMouvementEdition[];

    @JsonProperty('site', DeclarationSiteEdition)
    @Optional
    site: DeclarationSiteEdition;

    @JsonProperty('operateur', DeclarationOperateurEdition)
    @Optional
    operateur: DeclarationOperateurEdition;

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}
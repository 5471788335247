import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModeleFacturationService } from 'src/app/data/facturation/services/modele-facturation/modele-facturation.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ModeleFacturation } from 'src/app/data/facturation/models/modele-facturation.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { RefAnneeType } from 'src/app/data/facturation/models/ref-annee-type.model';
import { forkJoin } from 'rxjs';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import * as _ from 'lodash';
import { RefObjetFacture } from 'src/app/data/facturation/models/ref-objet-facture.model';
import { RefModeCalcul } from 'src/app/data/facturation/models/ref-mode-calcul.model';
import { RefQuantiteFacture } from 'src/app/data/facturation/models/ref-quantite-facture.model';
import { RefTva } from 'src/app/data/facturation/models/ref-tva.model';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { Cahier } from 'src/app/data/habilitation/models/cahier.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Component({
  selector: 'app-list-facturation',
  templateUrl: './list-facturation.component.html',
  styleUrls: ['./list-facturation.component.scss']
})
export class ListFacturationComponent implements OnInit {
  modelesFacturation: ModeleFacturation[] = [];
  refAnneeType: RefAnneeType[];
  refObjetsFactures: RefObjetFacture[];
  refModeCalcul: RefModeCalcul[];
  refQuantiteFacture: RefQuantiteFacture[];
  refTva: RefTva[];
  cahiers: Cahier[];

  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly modalsService: AdvBootstrapModalService,
              private readonly toastr: ToastrService,
              private readonly translate: TranslateService,
              private readonly modeleFacturationService: ModeleFacturationService,
              private readonly refFacturationService: ReferencesFacturationService,
              private readonly loaderService: AdvBootstrapLoaderService,
              private readonly referentielService: ReferentielService,
              private readonly cahiersService: CahiersService
  ) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    forkJoin(
      this.modeleFacturationService.getModelesFacturation(SessionContext.get('idOrganisme')),
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([modelesFacturation, cahiers, ref, reference]) => {
        this.modelesFacturation = modelesFacturation;
        this.modelesFacturation = _.sortBy(this.modelesFacturation, ['isSupprime']);
        this.refAnneeType = ref.refAnneeTypeList;
        this.refModeCalcul = ref.refModeCalculList;
        this.refObjetsFactures = ref.refObjetFactureList;
        this.refQuantiteFacture = ref.refQuantiteFactureList;
        this.refTva = ref.refTvaList;
        this.cahiers = cahiers;
      }
    );
  }

  editModeleFacturation(modeleFacturation: ModeleFacturation) {
    this.router.navigate([`main/facturation/modeles-facturation/${modeleFacturation.id}/edit`]);
  }

  addModeleFacturation() {
    this.router.navigate(['../../modeles-facturation/new/edit'], { relativeTo: this.route });
  }

  deleteModeleFacturation(modeleFacturation: ModeleFacturation) {
    this.modalsService.confirm(
      `${this.translate.instant('page.facturation.edit.alert.modele_facturation.confirm_remove.message')} ${modeleFacturation.libelle} ?`,
      this.translate.instant('page.facturation.edit.alert.modele_facturation.confirm_remove.title'),
      { cancelText: this.translate.instant(`label.annuler`), submitText: this.translate.instant(`label.valider`) }
    ).then(() => {
      this.modeleFacturationService.deleteModeleFacturation(SessionContext.get('idOrganisme'), modeleFacturation)
        .subscribe(() => {
          this.toastr.success(
            this.translate.instant('page.facturation.edit.alert.modele_facturation.suppression.message', { libelle: modeleFacturation.libelle }),
            this.translate.instant('page.facturation.edit.alert.modele_facturation.suppression.title'),
            { timeOut: 10 * 1000 }
          );
          this.loadData();
        });
    });
  }

  getGrouperOperateurValue(value: boolean): string {
    return value ? 'Oui' : 'Non';
  }

}

<div class="content">
  <div class="content-panel">
    <h2 translate>page.declarations.suivi-declarations.title</h2>
    <form [formGroup]="searchForm" #form="ngForm" (ngSubmit)="search()" novalidate >
      <div class="cols">
        <div class="col">
      <div class="form-row">
       
        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.declarations.suivi-declarations.list.annee</label>
            <input type="number" lang="en" formControlName="annee" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('annee').invalid}" />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.declarations.suivi-declarations.list.raisonSociale</label>
            <input type="text" lang="en" formControlName="raisonSociale" class="form-control" />
          </div>
        </div>
      
        <div class="col-md-2">
          <div class="form-group">
            <label translate>page.declarations.suivi-declarations.list.non_controlable</label>
            <select formControlName="non_controlable" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('non_controlable').invalid}">              
              <option Value="false">Contrôlable</option>
              <option Value="true">Non contrôlable</option>
            </select>
          </div>
        </div>  
          <div class="col-md-2">
            <div class="form-group">
              <label translate>page.declarations.suivi-declarations.list.suivi</label>
              <select formControlName="suivi" class="form-control" [ngClass]="{'is-invalid': form.submitted && searchForm.get('suivi').invalid}">              
                <option Value="undefined">Tous</option>
                <option Value="false">Non lu</option>
                <option Value="true">Lu</option>
              </select>
            </div>
          </div>
      </div>
      
      <div class="row">
  
        <div class="form-group col-md-5 row">
          <label for="debutDate" class="col-md-3 col-form-label"
            translate>page.declarations.suivi-declarations.list.dateEntre</label>
          <div class="col-md-5 input-group">
            <input id="debutDate" ngbDatepicker #debutDatepicker="ngbDatepicker"
              formControlName="debutDate" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="debutDatepicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-5 row">
          <label for="finDate" class="col-md-2 col-form-label"
            translate>page.declarations.suivi-declarations.list.et</label>
          <div class="col-md-5 input-group">
            <input id="finDate" ngbDatepicker #finDatepicker="ngbDatepicker"
              formControlName="finDate" class="form-control" />
            <div class="input-group-append">
              <button type="button" (click)="finDatepicker.toggle()" class="btn btn-secondary">
                <fa-icon icon="calendar-alt"></fa-icon>
              </button>
            </div>
          </div>
        </div>
    <div class="col-md-1">
      <button type="submit" class="btn btn-primary" translate>label.filtrer</button>
    </div>
  
  </div>
</div>
</div>
 </form>



    <table table class="table table-striped" *ngIf="historiques?.length > 0; else aucunHistorique">
      <thead>
        <tr>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.numeroDeclaration
          </th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.cvi<br/>
          page.declarations.suivi-declarations.table.header.raisonSociale</th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.typeDeclaration</th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.date</th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.volumeTotal</th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.produit</th>
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.annee</th>
          <th class="liste-col-header" colspan="2" translate>page.declarations.suivi-declarations.table.header.numero</th>
          
          <th class="liste-col-header" rowspan="2" translate>page.declarations.suivi-declarations.table.header.non_controlable</th>
          <th class="liste-col-header" rowspan="2" translate>
            page.declarations.suivi-declarations.table.header.statut
            <fa-icon icon="envelope-open" (click)="onPasserToutALuOuNonLu(true)"
              title="{{ 'page.declarations.suivi-declarations.table.header.toutALu' | translate }}"
              class="pointer icon"></fa-icon>
          </th>
          <th rowspan="2"></th>
        </tr>
        <tr>
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.numeroDossier</th>        
          <th class="liste-col-header" translate>page.declarations.suivi-declarations.table.header.numeroLot</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let historique of historiques">
          <td>{{ historique.numeroDeclaration}}</td>
          <td>{{ historique.numeroCVI}}
          <br/>{{ historique.raisonSociale }}</td>
          <td>{{ getTypeMouvement(historique.code) }}</td>
          <td>{{ historique.date | date:'dd/MM/yyyy' }}</td>
          <td>{{ historique.volume | number:'1.2-2' }}</td>
          <td>{{ historique.produit?.libelle }}</td>
          <td>{{ historique.annee }}</td>
          <td>{{ historique.numeroDossier }}</td>
          <td>{{ historique.numeroLot }}</td>
          <td style="text-align:center">
            <input type="checkbox" [checked]="historique.nonControlable" (change)="changeStatus(historique,$event.target?.checked)">
            <div >
                <h4  (click)="onEdit(historique)">
                  <fa-icon icon="info-circle"></fa-icon>                 
                </h4>
              </div>
          </td>
          <td>
            <span class="lue badge" *ngIf="historique.estLu" (click)="onChangerStatutNonLu(historique)"
              translate>page.declarations.suivi-declarations.table.header.lu</span>
            <span class="non-lue badge" *ngIf="!historique.estLu" (click)="onChangerStatut(historique)"
              translate>page.declarations.suivi-declarations.table.header.nonLu</span>
              
          </td>
          <td><button style="margin-left: 1em;" type="button" class="btn btn-outline-secondary btn-add"
            (click)="telechargerDocument(historique)">
            <fa-icon icon="download" class="primary-icon" title="Télécharger le document"></fa-icon>
          </button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Template aucun historique -->
<ng-template #aucunHistorique>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.declarations.suivi-declarations.aucunHistorique
  </div>
</ng-template>
<!-- Fin template aucun historique -->
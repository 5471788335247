<div class="content">
  <div class="content-panel">
    <h2 translate>page.facturation.modele.title</h2>

    <form *ngIf="formGroup" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()">

      <div class="cols">
        <!-- Colonne de gauche -->
        <div class="col">
          <div class="form-group">
            <label for="libelle" translate>page.facturation.modele.libelle</label>

            <input type="text" id="libelle" formControlName="libelle"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('libelle').invalid}" class="form-control" />
          </div>

          <div class="form-group">
            <label translate>page.facturation.modele.objet-facture</label>
            <select formControlName="refObjetFacture"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('refObjetFacture').invalid}"
              class="form-control">
              <option *ngFor="let refObjetFacture of refObjetsFactures" [ngValue]="refObjetFacture">
                {{refObjetFacture.libelle}}
              </option>
            </select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label translate>page.facturation.modele.annee-reference</label>
            <select formControlName="anneeRef"
              [ngClass]="{'is-invalid': form.submitted && formGroup.get('anneeRef').invalid}" class="form-control">
              <option *ngFor="let anneeRef of refAnneeType" [ngValue]="anneeRef">{{anneeRef.libelle}}</option>
            </select>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="recours" formControlName="grouperOperateur">
              <label class="form-check-label" for="grouperOperateur"
                translate>page.facturation.modele.grouper-operateur</label>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="facturationDirecte" formControlName="facturationDirecte">
              <label class="form-check-label" for="facturationDirecte"
                translate>page.facturation.modele.facturation-directe</label>
            </div>
          </div>

          <div class="form-group">
            <button style="position:relative;margin:1em;" type="submit" class="btn btn-primary"
              translate>page.facturation.modele.enregistrer-modifications</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="content-panel">
    <h2 translate>page.facturation.modele.articles.title</h2>

    <table table class="table table-striped">
      <thead>
        <tr>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.libelle</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.cdc</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.mode-calcul</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-facturee</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.prix-uni-ht</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-min</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.taux-tva</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.annees</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.actions</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let modeleArt of modelesArticle">
          <td>{{modeleArt.libelle}}</td>
          <td>
            <ul *ngFor="let cahier of modeleArt.cahiers">
              <li>
                {{cahier.libelle}}
              </li>
            </ul>
          </td>
          <td>{{modeleArt.refModeCalcul.libelle}}</td>
          <td>{{modeleArt.refQuantiteFacture.libelle}}</td>
          <td>{{modeleArt.prixUnitaire}}</td>
          <td>{{modeleArt.quantiteMinimum}}</td>
          <td>{{modeleArt.refTva.libelle}}</td>
          <td>
            <ul *ngFor="let campagne of modeleArt.campagnes">
              <li>
                {{campagne.annee}}
              </li>
            </ul>
          </td>
          <td>
            <span>
              <fa-icon class="clickable-icon" icon="edit" (click)="editModeleArticle(modeleArt)"></fa-icon>
            </span>
            <span>
              <fa-icon class="clickable-icon" icon="trash" (click)="deleteModeleArticle(modeleArt)"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <span class="h4" translate>page.facturation.modele.articles.title2 
      &nbsp;&nbsp;
      <input type="number" id="anneeFilter" style="width: 100px;"
      (keyup)="filterItemsOfAnnee()" [(ngModel)]="anneeFilter" />&nbsp;&nbsp;<button type="button" *ngIf="formGroup" class="btn btn-primary" [disabled]="!formGroup.valid" (click)="updateModeleArticleFilter()"
      translate>page.facturation.modele.articles.modifier-article-annee</button></span> 

    <table table class="table table-striped"  *ngIf="modelesArticleFilter?.length > 0; else aucunModeleArticleInAnnee">
      <thead>
        <tr>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.libelle</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.cdc</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.mode-calcul</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-facturee</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.prix-uni-ht</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.quantite-min</th>
          <th class="liste-col-header" translate>page.facturation.modele.articles.table.header.taux-tva</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let modeleArt of modelesArticleFilter">
          <td>{{modeleArt.libelle}}</td>
          <td>
            <ul *ngFor="let cahier of modeleArt.cahiers">
              <li>
                {{cahier.libelle}}
              </li>
            </ul>
          </td>
          <td>{{modeleArt.refModeCalcul.libelle}}</td>
          <td>{{modeleArt.refQuantiteFacture.libelle}}</td>
          <td>{{modeleArt.prixUnitaire}}</td>
          <td>{{modeleArt.prixMinimumHT}}</td>
          <td>{{modeleArt.refTva.libelle}}</td>                
        </tr>
      </tbody>
    </table>


    <div class="actions-bar">
      <button type="button" *ngIf="formGroup" class="btn btn-primary" [disabled]="!formGroup.valid" (click)="addModeleArticle()"
        translate>page.facturation.modele.articles.ajouter-article</button>
    </div>
  </div>

</div>

<!-- Template aucun historique -->
<ng-template #aucunModeleArticleInAnnee>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon> page.facturation.modele.articles.aucunModeleArticleInAnnee
  </div>
</ng-template>
<!-- Fin template aucun historique -->
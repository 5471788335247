<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.recherche-prestataire.title
    </h4>
  </div>

  <div class="modal-body">
    <div *ngIf="step === SEARCH_FRANCE">
      <h3 translate>
        page.declarations.synthese.modal.recherche-prestataire.recherche-prestataire-france
      </h3>

      <div *ngIf="prestatairesFrance && prestatairesFrance.length; else aucunPrestataire">
        <div class="searchBar">
          <label for="searchBar-input" translate>label.filtrer</label>
          <input type="text" id="searchBar-input2" (ngModelChange)="searchStringUpdate.next($event)" [(ngModel)]="searchStringInput" placeholder="{{'label.filtre'|translate}}">
        </div>

        <table class="table">
          <thead>
            <tr>
              <th translate>page.declarations.synthese.modal.recherche-prestataire.table.raisonSociale</th>
              <th translate>page.declarations.synthese.modal.recherche-prestataire.table.adresse</th>
              <th translate>page.declarations.synthese.modal.recherche-prestataire.table.codePostal</th>
              <th translate>page.declarations.synthese.modal.recherche-prestataire.table.ville</th>
              <th translate>page.declarations.synthese.modal.recherche-prestataire.table.pays</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prestataire of prestatairesFrance | filter : '*': searchString">
              <td>{{prestataire?.raisonSociale}}</td>
              <td>{{prestataire?.adresse}}</td>
              <td>{{prestataire?.codePostal}}</td>
              <td>{{prestataire?.ville}}</td>
              <td>{{prestataire?.pays?.libelle}}</td>
              <td class="align-right"><button class="btn btn-primary close-btn" (click)="selectPrestataireFrance(prestataire)"><span translate>label.selectionner</span></button></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="actions-bar flow flow-row" style="justify-content:space-between;">
        <button class="btn btn-primary" (click)="changeStep(ADD_FRANCE)" translate>
          <fa-icon icon="plus"></fa-icon>page.declarations.synthese.modal.recherche-prestataire.ajouter-prestataire
        </button>
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

    <div *ngIf="step === ADD_FRANCE">
      <h3 translate>
        <fa-icon icon="arrow-left" class="pointer" (click)="changeStep(SEARCH_FRANCE)"></fa-icon>page.declarations.synthese.modal.recherche-prestataire.ajouter-prestataire-france
      </h3>

      <form [formGroup]="formOperateur" #form="ngForm" (ngSubmit)="searchPrestataire()" novalidate>
        <div class="form-group">
          <label for="lot" translate>page.declarations.synthese.modal.recherche-prestataire.siret</label>
          <div class="flex flex-row siret-search">
            <input type="text" formControlName="siret" class="form-control" [ngClass]="{'is-invalid': form.submitted && formOperateur.get('siret').invalid}" />
            <button class="btn btn-primary" type="submit" translate>label.rechercher</button>
          </div>
        </div>
      </form>

      <table class="table" *ngIf="prestatairesFrance && prestatairesFrance.length; else aucunPrestataire">
        <thead>
          <tr>
            <th translate>page.declarations.synthese.modal.recherche-prestataire.table.raisonSociale</th>
            <th translate>page.declarations.synthese.modal.recherche-prestataire.table.adresse</th>
            <th translate>page.declarations.synthese.modal.recherche-prestataire.table.codePostal</th>
            <th translate>page.declarations.synthese.modal.recherche-prestataire.table.ville</th>
            <th translate>page.declarations.synthese.modal.recherche-prestataire.table.pays</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prestataire of prestatairesFrance">
            <td>{{prestataire?.raisonSociale}}</td>
            <td>{{prestataire?.adresse}}</td>
            <td>{{prestataire?.codePostal}}</td>
            <td>{{prestataire?.ville}}</td>
            <td>{{prestataire?.pays?.libelle}}</td>
            <td class="align-right"><button class="btn btn-primary close-btn" (click)="selectPrestataireFrance(prestataire)"><span translate>label.selectionner</span></button></td>
          </tr>
        </tbody>
      </table>

      <div class="actions-bar">
        <button class="btn btn-light" (click)="dismiss()" translate>label.annuler</button>
      </div>
    </div>

  </div>
</div>

<ng-template #aucunPrestataire>
  <div translate>
    page.declarations.synthese.modal.recherche-prestataire.aucun-prestataire
  </div>
</ng-template>

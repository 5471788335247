import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { Observable, forkJoin } from 'rxjs';
import { ReferencesFacturationService } from '../references-facturation/references-facturation.service';
import { ModeleFacturation } from '../../models/modele-facturation.model';
import { map } from 'rxjs/operators';
import { ModeleArticleService } from '../modele-article/modele-article.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { ModeleFacturationLight } from '../../models/modele-facturation-light.model';
import { ObjetFacture } from '../../models/objet-facture.model';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';

@Injectable({
  providedIn: 'root'
})
export class ModeleFacturationService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapper: JsonMapperService,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly modeleArticleService: ModeleArticleService,
    private readonly cahiersService: CahiersService,      
    private readonly referentielService: ReferentielService,
  ) {

  }

  getModelesFacturation(idOrganisme: number): Observable<ModeleFacturation[]> {
    return forkJoin(
      this.http.get<object[]>(`/api/facturation/private/organismes/${idOrganisme}/modeles`),
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences(),
      this.referentielService.getReferentiel()
    ).pipe(
      map(([modeleFacturation, cahiers, ref, reference]) =>  this.mapper.deserializeArray(modeleFacturation,
        ModeleFacturation, Object.assign({ cahiers }, reference , ref)))
    );
  }

  getModelesFacturationLight(idOrganisme: number): Observable<ModeleFacturationLight[]> {
    // return this.http.get<object[]>(`/assets/mocks/modeles-facturation-light.json`).pipe(
    return this.http.get<object[]>(`/api/facturation/private/organismes/${idOrganisme}/modelesLegers`).pipe(
      map(modeleFacturationLight => this.mapper.deserializeArray(modeleFacturationLight, ModeleFacturationLight))
    );
  }

  getModeleFacturationById(idOrganisme: number, idModeleFacturation: number): Observable<ModeleFacturation> {
    return forkJoin(
      this.http.get<object>(`/api/facturation/private/organismes/${idOrganisme}/modeles/${idModeleFacturation}`),
      this.cahiersService.getCahiers(),
      this.refFacturationService.getReferences(),
      this.referentielService.getReferentiel()// ,
      // this.modeleArticleService.getArticles(),
    ).pipe(
      map(([modeleFacturation, cahiers, ref, reference]) => this.mapper.deserializeObject(modeleFacturation,
        ModeleFacturation, Object.assign({ cahiers }, ref, reference))),
    );
  }


  createModeleFacturation(idOrganisme: number, modeleFacturation: ModeleFacturation): Observable<{ id: number, libelle: string }> {
    return this.http.post(`/api/facturation/private/organismes/${idOrganisme}/modeles`, this.mapper.serialize(modeleFacturation), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          libelle: response.body
        };
      })
    );
  }

  modifyModeleFacturation(idOrganisme: number, modeleFacturation: ModeleFacturation): Observable<{ id: number, libelle: string }> {
    return this.http.put(`/api/facturation/private/organismes/${idOrganisme}/modeles/${modeleFacturation.id}`, this.mapper.serialize(modeleFacturation), { observe: 'response', responseType: 'text' }).pipe(
      map(response => {
        return {
          id: parseInt(response.headers.get('location').split('/').pop(), 10),
          libelle: response.body
        };
      })
    );
  }

  deleteModeleFacturation(idOrganisme: number, modeleFacturation: ModeleFacturation): Observable<void> {
    return this.http.delete(`/api/facturation/private/organismes/${idOrganisme}/modeles/${modeleFacturation.id}`).pipe(
      map(() => { })
    );
  }

  facturationDirecte(code: string,idCahier: number): Observable<ModeleFacturationLight[]> {
    return this.http.get<ModeleFacturationLight[]>(`/api/facturation/private/modeleFacturation/${code}/facturationDirecte/${idCahier}`).pipe(
        map(modeleFacturationLight => this.mapper.deserializeArray(modeleFacturationLight, ModeleFacturationLight))
      );
  }
}

<div>
  <div class="modal-header">
    <h4 class="modal-title"  style="width : 100%">
       
        
        <div style="float: left;">
      <div translate [translateParams]="{ annee: annee }">
        page.declarations.detail-controle.title 
      </div>
      <br />
     
       
          {{ operateur?.raisonSociale }} - {{ cvi }}
          
      </div>
      <div class="px-2"  style="float: right;">
        <table class="table" *ngIf="syntheses" >
        <thead class="table-light">
          <tr>
            <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.annee</th>
            <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.libelleCDC</th>
            <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.nbLot</th>
            <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.nbControle</th>
          </tr>
        </thead>      
        <tbody class="table-dark">
          <tr *ngFor="let synthese of syntheses"  >
            <td>{{ synthese.annee }}</td>
            <td>{{ synthese.libelleCDC }}</td>
            <td>{{ synthese.nbLot }}</td>
            <td>{{ synthese.nbControle }}</td>
          </tr>
        </tbody>
       </table></div>
    
    </h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
  </div>
  
  <!-- Div de contenu de la fenêtre surgissante -->
  <div class="modal-body">

     <table table class="table table-striped" *ngIf="controles?.length > 0; else aucunControle">
      <thead>
        <tr>
          <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.produit</th>
          <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.millesime</th>
          <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.mvt</th>
          <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.volume</th>
          <th class="liste-col-header"  translate>page.declarations.detail-controle.table.header.date</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let controle of controles">
          <td>{{ controle.libelleProduit }}</td>
          <td>{{ controle.millesime }}</td>
          <td>{{ controle.libelleMouvement }}</td>
          <td>{{ controle.volume }}</td>
          <td>{{ controle.dateControle | date:'dd/MM/yyyy' }}</td>
        </tr>
      </tbody>
     </table>
  </div>
</div>

<ng-template #aucunControle>
  <div class="alert alert-warning alert-block" translate>
    <fa-icon icon="exclamation"></fa-icon>
    page.declarations.detail-controle.aucunControle
  </div>
</ng-template>
<div class="modal-content anpp">
  <div class="modal-header">
    <h5 class="modal-title">
      <b translate
        >page.declarations.import-inventaire-vergers.modal-description-champs.title</b
      >
    </h5>
    <button
      (click)="cancel()"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" translate>
            page.declarations.import-inventaire-vergers.modal-description-champs.columns.libelle
            <br />
            page.declarations.import-inventaire-vergers.modal-description-champs.columns.required
          </th>
          <th scope="col" translate>
            page.declarations.import-inventaire-vergers.modal-description-champs.columns.text
          </th>
          <th scope="col" translate>
            page.declarations.import-inventaire-vergers.modal-description-champs.columns.comment
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nom Producteur*</td>
          <td>Structure (identique à celle du site Innov-siqo)</td>
          <td>ex : COTEAU VERT (du)</td>
        </tr>
        <tr>
          <td>Raison Sociale*</td>
          <td>identique au nom du Producteur</td>
          <td></td>
        </tr>
        <tr>
          <td>Adresse</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Code Postal Producteur</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Commune Producteur</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Numéro Bloc</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Code Parcelle</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Numéro Tracabilite</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Référence Cadastrale</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Lieu dit Parcelle</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Code Postal Parcelle</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Commune Parcelle</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Espèce*</td>
          <td>
            <span *ngFor="let espece of especes">{{ espece.libelle }};</span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Variété*</td>
          <td>
            liste des variétés disponibles dans l'espace Documents publics
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Clone</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Porte Greffe</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Forme</td>

          <td>
            <span *ngFor="let forme of refFormes"> {{ forme.libelle }}; </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Distance Rang*</td>
          <td></td>
          <td>inscrire 2,5 si pas de données</td>
        </tr>
        <tr>
          <td>Distance Sur Rang*</td>
          <td></td>
          <td>inscrire 4 si pas de données</td>
        </tr>
        <tr>
          <td>Nb Abres*</td>
          <td></td>
          <td>correspond à la surface par mille</td>
        </tr>
        <tr>
          <td>Surface Calculée</td>
          <td></td>
          <td>en hectares</td>
        </tr>
        <tr>
          <td>Année Plantation*</td>
          <td>année : XXXX</td>
          <td></td>
        </tr>
        <tr>
          <td>Année Première Feuille</td>
          <td>année : XXXX</td>
          <td></td>
        </tr>
        <tr>
          <td>Année Surgreffage</td>
          <td>année : XXXX</td>
          <td></td>
        </tr>
        <tr>
          <td>Irrigation</td>
          <td>
            <span *ngFor="let irrigation of refSystemeIrrigations">
              {{ irrigation.libelle }};
            </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Protection Gel</td>
          <td>
            <span *ngFor="let gel of refProtectionGels">
              {{ gel.libelle }};
            </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Protection Grele</td>
          <td>
            <span *ngFor="let grele of refProtectionGreles">
              {{ grele.libelle }};
            </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Bio</td>

          <td>
            <span *ngFor="let bio of refCultureBios"> {{ bio.libelle }}; </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Situation</td>
          <td>
            <span *ngFor="let situation of refSituations">
              {{ situation.libelle }};
            </span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Confusion Sexuelle</td>
          <td>Non;Oui;</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      (click)="cancel()"
      class="btn btn-primary"
      style="background: #41905c; border: 1px solid #41905c"
      data-dismiss="modal"
      aria-label="Close"
      translate
    >
      page.inventaire-verger.buttonEnregistrer
    </button>
  </div>
</div>

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ControleDetails } from 'src/app/data/declaration/models/controle-details.model';
import { SyntheseDeclarationControle } from 'src/app/data/declaration/models/synthese-declaration-controle.model';
import { ControlesService } from 'src/app/data/declaration/services/controles/controles.service';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { SyntheseDeclarationControleService } from 'src/app/data/declaration/services/synthese-declaration-controle/synthese-declaration-controle.service';
import { OperateurInformationsDomaine } from 'src/app/data/intervenant/models/operateur-informations-domaine.model';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';

@Component({
  selector: 'app-detail-controle',
  templateUrl: './detail-controle.component.html',
  styleUrls: ['./detail-controle.component.scss']
})
export class DetailControleComponent implements OnInit {

  @Input() public idOperateur: number;
  @Input() public annee: string;  

  public controles: ControleDetails[];
  public syntheses: SyntheseDeclarationControle[];
  public operateur: Operateur;
  public cvi : string;

  constructor(
    private readonly controlesService: ControlesService,
    private readonly syntheseDeclarationControleService:SyntheseDeclarationControleService,
    private readonly operateursService: OperateursService,
    private readonly loaderService: AdvBootstrapLoaderService,
    public readonly modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly referentielService: ReferentielService,
    private readonly alertService: AdvBootstrapModalService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    // Charger les contrôles
    forkJoin(
      this.controlesService.getControlesDetails(this.idOperateur, this.annee),
      this.syntheseDeclarationControleService.getSynthese(this.idOperateur, this.annee),
      this.operateursService.getOperateur(this.idOperateur),
      this.operateursService.getInformationsDomaine(this.idOperateur)
    ).subscribe(([ controles, synthese, operateur, info]) => {
      [this.controles, this.syntheses, this.operateur] = [controles, synthese, operateur];

      this.cvi = info.find(i => i.code == "VIN_CVI").valeur;
     
    });
  }

  /** Ferme la fenête */
  public onClose(): void {
    this.modal.dismiss();
  }

}

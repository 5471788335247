import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';

import { AcheteursService } from 'src/app/data/declaration/services/acheteurs/acheteurs.service';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { AcheteurExport } from 'src/app/data/declaration/models/acheteur-export.model';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { Pays } from 'src/app/data/intervenant/models/pays.model';
import { DomaineSpec } from 'src/app/data/intervenant/models/domaine-spec.model';
import { Operateur } from 'src/app/data/intervenant/models/operateur.model';

@Component({
  selector: 'app-rechercher-prestataire',
  templateUrl: './rechercher-prestataire.component.html',
  styleUrls: ['./rechercher-prestataire.component.scss']
})
export class RechercherPrestataireComponent implements OnInit {

  readonly CHOIX_TYPE_ACHETEUR = 'CHOIX_TYPE_ACHETEUR';
  readonly SEARCH_FRANCE = 'SEARCH_FRANCE';
  readonly ADD_FRANCE = 'ADD_FRANCE';

  step = this.SEARCH_FRANCE;

  @Input()
  idOperateur: number;
  pays: Pays[];
  prestatairesFrance: Operateur[];

  formPrestataire: FormGroup;
  formOperateur: FormGroup;

  searchStringInput: string;
  searchString: string;
  searchStringUpdate = new Subject<string>();

  constructor(
    private readonly prestatairesService: AcheteursService,
    private readonly operateurService: OperateursService,
    private readonly modal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly loader: AdvBootstrapLoaderService,
    private readonly refService: ReferencesService
  ) { }

  ngOnInit() {
    this.searchStringUpdate.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchString = value;
      });

    this.formPrestataire = this.fb.group({
      raisonSociale: [undefined, Validators.required],
      adresse: [undefined],
      codePostal: [undefined, Validators.required],
      ville: [undefined, Validators.required],
      pays: [undefined, Validators.required],
    });

    this.formOperateur = this.fb.group({
      siret: [undefined, Validators.required]
    });
  }

  changeStep(step: string) {
    this.step = step;

    switch (step) {
      case this.SEARCH_FRANCE:
        this.prestatairesFrance = [];
        this.prestatairesService.getAcheteursFrance(this.idOperateur).pipe(
          this.loader.operator()
        ).subscribe(prestataires => {
          this.prestatairesFrance = prestataires;
        });
        break;
      case this.ADD_FRANCE:
        this.formOperateur.get('siret').reset(undefined, { emitEvent: false });
        break;
    }
  }

  dismiss() {
    this.modal.dismiss();
  }


  searchPrestataire() {
    if (this.formOperateur.valid) {
      this.operateurService.getAutresOperateurs({ code: 'SIRET' } as DomaineSpec, this.formOperateur.get('siret').value).pipe(
        this.loader.operator()
      ).subscribe(operateurs => {
        this.prestatairesFrance = operateurs;
      });
    }
  }

  selectPrestataireFrance(prestataire: Operateur) {
    this.modal.close(prestataire);
  }
}

import { JsonObject, JsonProperty, CustomConverter, Optional } from '@thorolf/json-ts-mapper';
import { CommissionCahierEdition } from './commission-cahier-edition.model';
import { CommissionEchantillonEdition } from './commission-echantillon-edition.model';
import { CommissionJureEdition } from './commission-jure-edition.model';
import { OrganismeEdition } from './organisme-edition.model';
import { CommissionSyntheseEdition } from './commission-synthese-edition.model';
import { CommissionJuryEdition } from './commission-jury-edition.model';

@JsonObject
export class CommissionEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('date', String)
    @Optional
    date: string;

    @JsonProperty('lieu', String)
    @Optional
    lieu: string;

    @JsonProperty('codepostal', String)
    @Optional
    codepostal: string;

    @JsonProperty('ville', String)
    @Optional
    ville: string;
    
    @JsonProperty('adresse', String)
    @Optional
    adresse: string;

    @JsonProperty('numero', String)
    @Optional
    numero: string;

    @JsonProperty('heure', String)
    @Optional
    heure: string;   


    @JsonProperty('nbJures', String)
    @Optional
    nbJures: string;

    @JsonProperty('nbJuresPresents', String)
    @Optional
    nbJuresPresents: string;

    @JsonProperty('responsable', String)
    @Optional
    responsable: string;

    @JsonProperty('idResponsable', Number)
    @Optional
    idResponsable: number;

    @JsonProperty('synthese', CommissionSyntheseEdition)
    @Optional
    synthese: CommissionSyntheseEdition;

    @JsonProperty('cahiers', [CommissionCahierEdition])
    @Optional
    cahiers: CommissionCahierEdition[];

    @JsonProperty('echantillons', [CommissionEchantillonEdition])
    @Optional
    echantillons: CommissionEchantillonEdition[];

    @JsonProperty('jures', [CommissionJureEdition])
    @Optional
    jures: CommissionJureEdition[];

    @JsonProperty('jurys', [CommissionJuryEdition])
    @Optional
    jurys: CommissionJuryEdition[];

    @JsonProperty('organisme', [OrganismeEdition])
    @Optional
    organisme: OrganismeEdition;
}

import { JsonProperty, JsonObject, Optional } from '@thorolf/json-ts-mapper';

@JsonObject
export class DeclarationMouvementEdition {
    @JsonProperty('id', Number)
    id: number;

    @JsonProperty('millesime', String)
    millesime: string;
    
    @JsonProperty('cahier', String)
    cahier: string;
    
    @JsonProperty('produit', String)
    produit: string;

    @JsonProperty('couleur', String)
    couleur: string;


    @JsonProperty('produitOrigine', String)
    @Optional
    produitOrigine: string;

    @JsonProperty('numeroLot', String)
    numeroLot: string;

    @JsonProperty('numeroDossier', String)
    @Optional
    numeroDossier: string;

    @JsonProperty('cepage', String)
    cepage: number;

    @JsonProperty('volume', String)
    volume: string;

    @JsonProperty('type', String)
    type: string;

    @JsonProperty('logement', String)
    logement: string;

    @JsonProperty('contenant', String)
    contenant: string;

    @JsonProperty('date', String)
    date: string;

    @JsonProperty('dateRetiraison', String)
    dateRetiraison: string;

    @JsonProperty('typeConditionnement', String)
    typeConditionnement: string;

    @JsonProperty('degre', String)
    degre: string;
}

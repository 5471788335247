<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>page.facturation.modele.modals.article.form.title</h4>
  </div>

  <div class="modal-body">
    <form *ngIf="loaded" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="submit()">

      <div class="form-section">
        <div class="form-group">
          <label for="libelle" translate>page.facturation.modele.modals.article.form.libelle</label>

          <input type="text" id="libelle" formControlName="libelle"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('libelle').invalid}" class="form-control" />

        </div>

        <div class="form-group">
          <label translate>page.facturation.modele.modals.article.form.mode-calcul</label>
          <select formControlName="modeCalcul" (change)="changeModeCalcul()"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('modeCalcul').invalid}" class="form-control">
            <option *ngFor="let modeCalcul of refModeCalcul" [ngValue]="modeCalcul">{{modeCalcul.libelle}}</option>
          </select>
        </div>
      </div>

      <div class="form-section">
        <div class="form-group">
          <label for="libelle" translate>page.facturation.modele.modals.article.form.prix-uni-ht</label>
          <input type="number" id="prixUnitaireHT" formControlName="prixUnitaireHT" min="0"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('prixUnitaireHT').invalid}"
            class="form-control" />

        </div>

        <div class="form-group">
          <label for="libelle" translate>page.facturation.modele.modals.article.form.quantite-min</label>
          <input type="number" id="quantiteMinimum" formControlName="quantiteMinimum" min="0"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('quantiteMinimum').invalid}" class="form-control" />

        </div>

        <div class="form-group">
          <label translate>page.facturation.modele.modals.article.form.type-quantite</label>
          <select formControlName="typeQteFacture"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('typeQteFacture').invalid}" class="form-control">
            <option *ngFor="let typeQteFacture of refQuantitesFactureFilter" [ngValue]="typeQteFacture">
              {{typeQteFacture.libelle}}</option>
          </select>
        </div>

      </div>

      <div class="form-section">

        <div class="form-group">
          <label translate>page.facturation.modele.modals.article.form.cdc</label>
          <app-multiselect id="select-cdc" maxWidth="initial" bindLabel="libelle" controlName="cdc" [items]="cahiers"
            [ngClass]="{'is-invalid': form.submitted && formGroup.get('cdc').invalid}"></app-multiselect>
        </div>
      </div>
      <div class="form-section">
        <div class="form-group">
          <label translate>page.facturation.modele.modals.article.form.taux-tva</label>
          <label *ngFor="let tauxTVA of refTva;">
            <input formControlName="tauxTVA" type="radio" name="tauxTVA" [value]="tauxTVA" />
            {{tauxTVA.libelle}}
          </label>
        </div>

        
        <div class="form-group">
          <label translate>
            page.controles.pression-controle.form.annees
            <fa-icon icon="plus" class="clickable-icon icon-add" title="Ajouter" (click)="onAddYear()"></fa-icon>
          </label>
          <ul formArrayName="annees">
            <li *ngFor="let annee of getYears(); let i = index" [formGroupName]="i">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <input type="number" class="form-control small-length" lang="en" formControlName="annee" [ngClass]="{ 'is-invalid': form.submitted && formGroup.get('annees.'+i+'.annee').invalid }" />
                </div>
                <div class="form-group col-md-1">
                  <fa-icon icon="trash" class="clickable-icon" title="Supprimer" (click)="onDeleteYear(i)"></fa-icon>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class=" actionsButtons">
        <button style="position:relative;margin:1em;" type="submit" class="btn btn-primary"
          translate>page.facturation.modele.modals.article.form.enregistrer-modifications</button>
        <button type="button" class="btn btn-light" (click)="cancel()" translate>label.annuler</button>
      </div>
    </form>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonMapperService } from 'src/app/core/services/mapper/mapper.service';
import { SyntheseDeclarationControle } from '../../models/synthese-declaration-controle.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SyntheseDeclarationControleService {

  constructor(
    protected readonly http: HttpClient,
    protected readonly mapper: JsonMapperService
  ) { }

  getSynthese(idIntervenant: number, annee: string): Observable<SyntheseDeclarationControle[]> {
    return this.http.get<object[]>(`/api/declaration/private/intervenants/${idIntervenant}/synthese-declaration-controle/${annee}`
    ).pipe(
      map(response => this.mapper.deserializeArray(response, SyntheseDeclarationControle)),
    );
  }
}

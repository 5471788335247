<div>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'page.declarations.synthese.modal.saisirTirage.tirage' | translate }}</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="formSaisieTirage" #form="ngForm" *ngIf="loaded" (ngSubmit)="submit()" >
      <div class="cols">
        <div class="col">
          <!-- produit -->
          <div class="form-group">
            <label for="produits">{{ 'page.declarations.synthese.modal.saisirTirage.produit' | translate }}</label>
            <select formControlName="produits" class="form-control" [ngClass]="{'is-invalid': form.submitted && form_produits.invalid, 'select-placeholder' : (produit?.value === null && !produit?.dirty)}">
              <option *ngFor="let produit of produits" [ngValue]="produit">{{ produit?.libelle }}</option>
            </select>
          </div>
          <!-- date de tirage -->
          <div class="form-group">
            <label for="date">{{ 'page.declarations.synthese.modal.saisirTirage.dateTirage' | translate }}</label>
            <div class="input-group">
              <input type="text" formControlName="date" id="date" name="date" class="form-control" ngbDatepicker #datePicker="ngbDatepicker" required [ngClass]="{'is-invalid': form.submitted && date.invalid, 'select-placeholder' : (date.value === null && !date.dirty)}" />
              <div class="input-group-append">
                <button type="button" (click)="datePicker.toggle()" class="btn btn-secondary">
                  <fa-icon icon="calendar-alt"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- numéro tirage -->
          <div class="form-group">
            <label for="numeroTirage">{{ 'page.declarations.synthese.modal.saisirTirage.numeroTirage' | translate }}</label>
            <input type="text" formControlName="numeroTirage" id="numeroTirage" name="numeroTirage" class="form-control" required [ngClass]="{'is-invalid': form.submitted && numeroTirage.invalid, 'select-placeholder' : (numeroTirage.value === null && !numeroTirage.dirty)}" />
          </div>
          <!-- numéro de lot -->
          <div class="form-group">
            <label for="lot">{{ 'page.declarations.synthese.modal.saisirTirage.numeroLot' | translate }}</label>
            <input type="text" formControlName="lot" id="lot" name="lot" class="form-control" required [ngClass]="{'is-invalid': form.submitted && lot.invalid, 'select-placeholder' : (lot.value === null && !lot.dirty)}" />
          </div>
          <!-- type de vin -->
          <div class="form-group">
            <label for="typeVin">{{ 'page.declarations.synthese.modal.saisirTirage.typeVin' | translate }}</label>
            <select formControlName="typeVin" class="form-control" id="typeVin" name="typeVin" required [ngClass]="{'is-invalid': form.submitted && getField('typeVin').invalid, 'select-placeholder' : (typeVin?.id === null && !typeVin?.dirty)}">
              <option *ngFor="let typeVin of referentiel.refMvtTypesVin" [ngValue]="typeVin">{{ typeVin?.libelle }}</option>
            </select>
          </div>                  
          <!-- prestataire de service -->
          <div class="form-group">
            <label for="prestataire" translate>page.declarations.synthese.modal.saisirTirage.prestataireService</label>
            <div class="flex flex-row acheteur-form-row">
              <input type="text" [ngModelOptions]="{standalone: true}" [ngModel]="raisonSocialeAcheteur" id="prestataire" name="prestataire" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('prestataire').invalid}" disabled/>
              <button type="button" class="btn btn-light" (click)="rechercherPrestataire()" translate>page.declarations.synthese.modal.saisirTirage.modifier-prestataire</button>
            </div>
          </div>
          <!-- Entreposage -->
          <div class="form-group">
            <label for="entreposage" translate>page.declarations.synthese.modal.saisirTirage.entreposage</label>
            <select formControlName="entreposage" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('entreposage').invalid, 'select-placeholder' : (getField('entreposage').value === null && !getField('entreposage').dirty)}">
              <option [ngValue]="null" hidden translate>page.declarations.synthese.modal.saisirTirage.liste-sites</option>
              <option *ngFor="let site of sitesOperateur" [ngValue]="site">{{ site.adresse }}</option>
            </select>
          </div>
          <!-- -->
        </div>

        <div class="col">
          <div class="form-group">
            <label for="volume">{{'page.declarations.synthese.modal.saisirTirage.volumeVinBase' | translate }} ({{'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label>
            <div class="flex flex-row">
              <input type="number" formControlName="volume" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('volume').invalid}" />
              <span *ngIf="typeCahier !== 'IGP'"  class="volume-max">&nbsp;({{ 'page.declarations.synthese.modal.saisirTirage.max' | translate: { volume:(stockCourant?.stock) ? stockCourant.stock : 0 } }})</span>  
            </div>

            <div class="alert-information" *ngIf="displayAlertMillesime" translate>
              <fa-icon icon="exclamation"></fa-icon>
              page.declarations.synthese.modal.saisirTirage.alertMillesime
            </div>
          </div>

          <hr/>

          <!-- Formulaire assemblages -->
          <div formArrayName="assemblages" *ngIf="typeCahier !== 'IGP'">
            <table *ngIf="getAssemblages().controls?.length" class="table-assemblages form-group">
              <tr>
                <td><label translate>page.declarations.synthese.modal.saisirTirage.annee</label></td>
                <td><label translate>page.declarations.synthese.modal.saisirTirage.volume</label></td>
              </tr>
              <tr *ngFor="let assemblage of getAssemblages().controls; let i = index" [formGroupName]="i">
                <td>
                  <select formControlName="annee" class="form-control" required [ngClass]="{'is-invalid': form.submitted && getField('assemblages.' + i + '.annee').invalid}">
                    <option [ngValue]="null" hidden selected></option>
                    <option *ngFor="let stock of stocksProduit" [ngValue]="stock">{{ stock.annee }}</option>
                  </select>
                </td>
                <td>
                  <input type="number" lang="en" style="width:12em;" formControlName="volume" class="form-control" [readonly]="getField('assemblages.' + i + '.annee').value == null" [ngClass]="{'is-invalid': form.submitted && getField('assemblages.' + i + '.volume').invalid}" />
                </td>
                <td>
                  <span class="volume-max" *ngIf="getField('assemblages.' + i + '.annee').value">({{ 'page.declarations.synthese.modal.saisirTirage.max' | translate:{volume:getField('assemblages.' + i + '.annee').value.stock} }})</span>
                </td>
                <td>
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisirTirage.supprimerAssemblage' | translate }}" (click)="removeAssemblage(i)"></fa-icon>
                </td>
              </tr>
            </table>

            <button class="lienAffichage" type="button" (click)="addAssemblage()" [disabled]="stocksProduit.length == 0">
              <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisirTirage.creerAssemblage' | translate }}
            </button>
            <div class="alert-information" *ngIf="stocksProduit.length == 0" translate>
              <fa-icon icon="exclamation"></fa-icon>
              page.declarations.synthese.modal.saisirTirage.alertAucunStock
            </div>
          </div>

          <hr/>
          
          <label for="volumeLiqueur">{{'page.declarations.synthese.modal.saisirTirage.volumeLiqueur' | translate }} ({{'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label>
          <div class="flex flex-row">
            <input type="number" formControlName="volumeLiqueur" class="form-control" [ngClass]="{'is-invalid': form.submitted && getField('volumeLiqueur').invalid}" />
          </div>          

          <div class="form-group">
            <label for="volumeTotal">{{ 'page.declarations.synthese.modal.saisirTirage.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label>
            <input type="number" [ngModelOptions]="{standalone: true}" [ngModel]="volumeTotalValue" id="volumeTotal" name="volumeTotal" class="form-control" disabled/>
          </div>

          <hr/>


          <!-- Formulaire Contenants -->
          <div formArrayName="contenants" [ngClass]="{'is-invalid': form.submitted && formSaisieTirage.get('assemblages').invalid, 'select-placeholder' : (formSaisieTirage.get('assemblages').value === null && !formSaisieTirage.get('assemblages').dirty)}">
            <div *ngFor="let contenant of getContenants(); let i = index" [formGroupName]="i">
              <div class="form-row" *ngIf="i == 0">
                <div class="form-group col-md-5"><label>{{ 'page.declarations.synthese.modal.saisirTirage.type' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirTirage.nombre' | translate }}</label></div>
                <div class="form-group col-md-3"><label>{{ 'page.declarations.synthese.modal.saisirTirage.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirTirage.hL' | translate }})</label></div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-5">
                  <select formControlName="type" class="form-control" (change)="onTypeChange(i)" required [ngClass]="{'is-invalid': form.submitted && formSaisieTirage.get('contenants.' + i + '.type').invalid, 'select-placeholder' : (formSaisieTirage.get('contenants.' + i + '.type').value === null && !formSaisieTirage.get('contenants.' + i + '.type').dirty)}">
                    <option [ngValue]="null" hidden selected>{{ 'page.declarations.synthese.modal.saisirTirage.choisirType' | translate }}</option>
                    <option *ngFor="let refContenant of referentiel.refContenantList" [ngValue]="refContenant">{{ refContenant.libelleContenant }}</option>
                  </select>
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="nombre" class="form-control" [readonly]="formSaisieTirage.get('contenants.' + i + '.type').value == null" required [ngClass]="{'is-invalid': form.submitted && formSaisieTirage.get('contenants.' + i + '.nombre').invalid}" />
                </div>

                <div class="form-group col-md-3">
                  <input type="number" lang="en" formControlName="volume" class="form-control" [readonly]="formSaisieTirage.get('contenants.' + i + '.type').value == null" required [ngClass]="{'is-invalid': form.submitted && formSaisieTirage.get('contenants.' + i + '.volume').invalid}" />
                </div>

                <div class="form-group col-md-1">
                  <fa-icon class="pointer deleteIcon" icon="trash" title="{{ 'page.declarations.synthese.modal.saisirTirage.supprimerContenant' | translate }}" (click)="remove('contenants', i)"></fa-icon>
                </div>
              </div>
            </div>
          </div>

          <button class="lienAffichage" type="button" (click)="addContenant('contenants')">
            <fa-icon icon="plus"></fa-icon> {{ 'page.declarations.synthese.modal.saisirTirage.creerContenant' | translate }}
          </button>
        </div>
      </div>



      <!-- Boutons d'action du composant -->
      <div class="actions-bar">
        <button type="button" (click)="close()" class="btn btn-light" translate>label.annuler</button>
        <button type="submit" class="btn btn-primary" translate>label.valider</button>
      </div>
    </form>
  </div>
</div>

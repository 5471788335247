import { JsonObject, JsonProperty } from "@thorolf/json-ts-mapper";

@JsonObject
export class SyntheseDeclarationControle {

  @JsonProperty('annee', String)
  annee: string ;

  @JsonProperty('libelleCDC', String)
  libelleCDC: string ;


  @JsonProperty('nbLot', Number)
  nbLot = 0;

  @JsonProperty('nbControle', Number)
  nbControle = 0;
 
}
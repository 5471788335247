import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { AdvBootstrapModalService } from "@adv/bootstrap-modal";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ExportToCsv } from "export-to-csv";
import { remove } from "lodash";
import * as moment from "moment";
import { forkJoin, from } from "rxjs";
import { Campagne } from "src/app/data/declaration/models/campagne.model";
import { RefCampagnesService } from "src/app/data/declaration/services/ref-campagnes/ref-campagnes.service";
import { RefSoustypeUtilisateur } from "src/app/data/droits/models/ref-soustype-utilisateur.model";
import { EngagementBase } from "src/app/data/habilitation/models/engagements/engagement-base";
import { EngagementVergersEcoService } from "src/app/data/habilitation/services/engagements/engagement-vergers-eco.service";
import { Entreprise } from "src/app/data/intervenant/models/entreprise.model";
import { EspeceEntreprise } from "src/app/data/intervenant/models/espece-entreprise.model";
import { Terroir } from "src/app/data/intervenant/models/terroir.model";
import { EntreprisesService } from "src/app/data/intervenant/services/entreprises/entreprises.service";
import { RefSousTypesService } from "src/app/data/intervenant/services/ref-sous-types/ref-sous-types.service";
import { TerroirsService } from "src/app/data/intervenant/services/terroirs/terroirs.service";
import { Departement } from "src/app/shared/models/departement.model";
import { DepartementService } from "src/app/shared/services/dep-commune/departement.service";
import { SelectEntrepriseColonneComponent } from "./modals/select-entreprise-colonne/select-entreprise-colonne.component";

@Component({
  selector: "app-liste-entreprises",
  templateUrl: "./liste-entreprises.component.html",
  styleUrls: ["./liste-entreprises.component.scss"],
})
export class ListeEntreprisesComponent implements OnInit {
  filtrer: boolean = true;

  entreprises: Entreprise[];
  entreprisesFilter: Entreprise[];

  terroirs: Terroir[];
  departements: Departement[];

  especes: EspeceEntreprise[];

  refSousTypes: RefSoustypeUtilisateur[];

  raisonsocialeFilter: String;
  communeFilter: String;
  estadherentFilter: Boolean;
  refsoustypeFilter: RefSoustypeUtilisateur;
  entrepriselieeFilter: String;
  ecoresponsableFilter: Boolean;
  culturebioFilter: Boolean;
  telephoneFilter: String;
  mailFilter: String;
  terroirFilter: Terroir;
  departementFilter: String;
  actifFilter: Boolean;
  especesproduitesFilter: String;
  anneedemissionFilter: String;
  campagneEnCours: Campagne;

  estEntrepriseActiveFilter: boolean = true;

  exportData: {};

  headers = [
    { name: "raisonsociale", libelle: "Raison Sociale", checked: true },
    { name: "commune", libelle: "Ville", checked: true },
    { name: "refsoustype", libelle: "Type de l'entreprise", checked: true },
    { name: "entrepriseliee", libelle: "GP", checked: true },
    { name: "estadherent", libelle: "Adhérent ANPP", checked: true },
    {
      name: "ecoresponsable",
      libelle: 'Agrément "Vergers écoresponsables"',
      checked: true,
    },
    { name: "culturebio", libelle: "Culture Bio", checked: true },
    { name: "telephone", libelle: "Téléphone", checked: false },
    { name: "mail", libelle: "Email", checked: false },
    { name: "terroir", libelle: "Terroir", checked: false },
    { name: "departement", libelle: "Département", checked: false },
    { name: "especesproduites", libelle: "Espèces produites", checked: false },
    { name: "anneedemission", libelle: "Année de démission", checked: false },
  ];

  constructor(
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly router: Router,
    private readonly entreprisesService: EntreprisesService,
    private readonly refSousTypeService: RefSousTypesService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly terroirService: TerroirsService,
    private readonly departementService: DepartementService,
    private readonly refCampagnesService: RefCampagnesService,
    private readonly engagementVergersEcoService: EngagementVergersEcoService
  ) {}

  ngOnInit() {
    this.entreprisesService
      .getEspecesByEntreprise()
      .pipe(this.loaderService.operator())
      .subscribe((especes) => {
        this.especes = especes;
        this.getEntreprises(true);
      });
  }

  getEntreprises(estEntrepriseActiveFilter: boolean) {
    forkJoin(
      estEntrepriseActiveFilter
        ? this.entreprisesService.getEntreprises()
        : this.entreprisesService.getEntreprisesInactives(),
      this.refSousTypeService.getRefSousType(),
      this.departementService.getLocalisationDepartements(),
      this.terroirService.getTerroirs(),
      this.refCampagnesService.getCampagneEnCours()
    )
      .pipe(this.loaderService.operator())
      .subscribe(
        ([
          entreprises,
          refsoustypes,
          departements,
          terroirs,
          campagneEncours,
        ]) => {
          this.campagneEnCours = campagneEncours;
          this.entreprises = entreprises;
          this.terroirs = terroirs;
          this.departements = departements;
          this.entreprisesFilter = entreprises;
          this.refSousTypes = refsoustypes;

          this.loadEngagementVerger();
        }
      );
  }

  private loadEngagementVerger(): void {
    if (this.campagneEnCours) {
      this.engagementVergersEcoService
        .getEngagementEcoByCampagne(this.campagneEnCours.code)
        .subscribe((engagements) => {
          if (engagements != null) {
            engagements.forEach((engagement: EngagementBase) => {
              if (
                engagement.codeStatut === "AE" ||
                engagement.codeStatut === "C"
              ) {
                let entreprise = this.entreprises.find(
                  (entreprise) => entreprise.id === engagement.entreprise.id
                );
                if (entreprise) {
                  entreprise.estEngageeVer = true;
                }
              }
            });
          }
        });
    }
  }

  editEntreprise(entreprise: Entreprise) {
    this.router.navigate(["fiche", entreprise.id], { relativeTo: this.route });
  }

  deleteEntreprise(entreprise: Entreprise) {
    this.entreprisesService.supprimerEntreprise(entreprise.id).subscribe(() => {
      remove(this.entreprises, entreprise);
    });
  }

  openConfirmationDialog(entreprise: Entreprise) {
    this.modalsService
      .confirm(
        "Voulez vous supprimer l'entreprise " + entreprise.raisonSociale + " ?",
        "Veuillez confirmer",
        {
          cancelText: this.translate.instant(`label.annuler`),
          submitText: this.translate.instant(`label.confirmer`),
          windowClass: "anpp",
        }
      )
      .then(
        () => {
          this.deleteEntreprise(entreprise);
        },
        () => {}
      );
  }

  openConfirmationReactivationDialog(entreprise: Entreprise) {
    this.modalsService
      .confirm(
        "Voulez vous réactiver l'entreprise " + entreprise.raisonSociale + " ?",
        "Veuillez confirmer",
        {
          cancelText: this.translate.instant(`label.annuler`),
          submitText: this.translate.instant(`label.confirmer`),
          windowClass: "anpp",
        }
      )
      .then(
        () => {
          this.entreprisesService
            .reactiverEntreprise(entreprise.id)
            .subscribe(() => {
              this.getEntreprises(this.estEntrepriseActiveFilter);
            });
        },
        () => {}
      );
  }

  getTerroir(departement: String) {
    if (departement) {
      const dept = this.departements.find((d) => d.code === departement).code;

      const terroir_dept = this.terroirs.find((t) => {
        if (t.departements) return t.departements.indexOf(dept) !== -1;
      });

      if (terroir_dept) return terroir_dept.libelle;
      else "";
    }
  }

  getEspeces(IdEntreprise) {
    const espece = this.especes.find((e) => e.idEntreprise === IdEntreprise);

    if (espece) {
      return espece.codeEspece;
    } else {
      return [];
    }
  }

  demissionPO(entreprise: Entreprise) {
    this.modalsService
      .confirm(
        "Veuillez confirmer",
        "Voulez vous démissionner l'entreprise " +
          entreprise.raisonSociale +
          " ?",
        {
          cancelText: this.translate.instant(`label.annuler`),
          submitText: this.translate.instant(`label.confirmer`),
          windowClass: "anpp",
        }
      )
      .then(
        () => {
          entreprise.entrepriseLiee = undefined;
          entreprise.dateDemission = moment(new Date());
          entreprise.refSousType = this.refSousTypes.find(
            (r) => r.code === "PNA_DEFAUT"
          );

          this.entreprisesService
            .modifierEntreprise(entreprise)
            .subscribe(() => {});
        },
        () => {}
      );
  }

  afficheFiltre() {
    this.filtrer = !this.filtrer;
  }

  afficherListe() {
    const modalRef = this.modalService.open(SelectEntrepriseColonneComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.headers = this.headers;

    from(modalRef.result).subscribe((headers) => {
      this.headers = headers;
    });
  }

  show(obj) {
    return this.headers.find((h) => h["name"] === obj).checked;
  }

  getAnnee(annee: moment.Moment) {
    if (annee) return annee.year();
  }

  filter() {
    if (this.entreprises && this.entreprises.length) {
      this.entreprisesFilter = this.entreprises.filter((item) => {
        if (this.getFilterString(this.raisonsocialeFilter, item.raisonSociale))
          return false;
        if (this.getFilterString(this.communeFilter, item.commune))
          return false;
        if (this.getFilterString(this.telephoneFilter, item.telephone))
          return false;
        if (this.getFilterString(this.mailFilter, item.mail)) return false;
        if (this.getFilterString(this.departementFilter, item.departement))
          return false;
        if (
          this.getFilterString(
            this.entrepriselieeFilter,
            item.entrepriseLiee !== undefined
              ? item.entrepriseLiee.raisonSociale
              : ""
          )
        )
          return false;

        if (
          this.getFilterString(
            this.especesproduitesFilter,
            this.getEspeces(item.id).join(" ")
          )
        )
          return false;

        if (this.getFilterBoolean(this.estadherentFilter, item.estAdherent))
          return false;
        if (this.getFilterBoolean(this.culturebioFilter, item.cultureBio))
          return false;
        if (
          this.getFilterBoolean(this.ecoresponsableFilter, item.estEngageeVer)
        )
          return false;

        if (
          this.anneedemissionFilter &&
          (item.dateDemission === undefined ||
            item.dateDemission === null ||
            (item.dateDemission &&
              item.dateDemission
                .format("YYYY")
                .toLowerCase()
                .indexOf(this.anneedemissionFilter.toLowerCase()) === -1))
        ) {
          return false;
        }

        if (
          this.refsoustypeFilter &&
          !(item.refSousType.id === this.refsoustypeFilter.id)
        ) {
          return false;
        }
        if (
          this.terroirFilter &&
          !(
            item.departement &&
            this.getTerroir(item.departement) === this.terroirFilter.libelle
          )
        ) {
          return false;
        }

        return true;
      });
    }
  }

  getFilterString(filter: String, item: String) {
    if (
      filter &&
      ((item && item.toLowerCase().indexOf(filter.toLowerCase()) === -1) ||
        item === undefined ||
        item === "")
    ) {
      return true;
    }
    return false;
  }

  getFilterBoolean(filter: Boolean, item: Boolean) {
    if (
      filter !== undefined &&
      !((item === undefined ? false : item) === filter)
    ) {
      return true;
    }
    return false;
  }

  //Export CSV
  exporterEntreprises(): void {
    const data = [];
    const headersCSV = [];

    this.headers
      .filter((head) => head.checked)
      .forEach((header) => {
        headersCSV.push(header["libelle"]);
      });

    this.entreprisesFilter.forEach((entreprise) => {
      this.exportData = {};

      if (this.show("raisonsociale"))
        this.exportData["raisonsociale"] = entreprise.raisonSociale;
      if (this.show("commune")) this.exportData["commune"] = entreprise.commune;
      if (this.show("refsoustype"))
        this.exportData["refsoustype"] = entreprise.refSousType.libelle;
      if (this.show("entrepriseliee"))
        this.exportData["entrepriseliee"] =
          entreprise.entrepriseLiee !== undefined
            ? entreprise.entrepriseLiee.raisonSociale
            : "";
      if (this.show("estadherent"))
        this.exportData["estadherent"] = entreprise.estAdherent ? "Oui" : "Non";
      if (this.show("ecoresponsable"))
        this.exportData["ecoresponsable"] = entreprise.estAdherent
          ? "Oui"
          : "Non";
      if (this.show("culturebio"))
        this.exportData["culturebio"] = entreprise.cultureBio ? "Oui" : "Non";
      if (this.show("telephone"))
        this.exportData["telephone"] =
          entreprise.telephone !== undefined ? entreprise.telephone : "";
      if (this.show("mail")) this.exportData["mail"] = entreprise.mail;
      if (this.show("terroir")) this.exportData["terroir"] = " ";
      if (this.show("departement"))
        this.exportData["departement"] = entreprise.departement;
      if (this.show("especesproduites"))
        this.exportData["especesproduites"] = " ";
      if (this.show("anneedemission"))
        this.exportData["anneedemission"] =
          entreprise.dateDemission !== undefined
            ? entreprise.dateDemission.format("YYYY")
            : "";

      data.push(this.exportData);
    });

    const options = {
      filename: "EXPORT_ENTREPRISE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      fieldSeparator: ";",
      quoteStrings: "",
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "EXPORT_ENTREPRISE"
        .concat("_")
        .concat(moment().format("YYYYMMDD")),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headersCSV,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  changeEstEntrepriseActiveFilter() {
    this.getEntreprises(this.estEntrepriseActiveFilter);
  }
}

import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReferentielService } from 'src/app/data/declaration/services/referentiel/referentiel.service';
import { ModeleArticle } from 'src/app/data/facturation/models/modele-article.model';
import { ModeleArticleService } from 'src/app/data/facturation/services/modele-article/modele-article.service';
import { ReferencesFacturationService } from 'src/app/data/facturation/services/references-facturation/references-facturation.service';
import { CahiersService } from 'src/app/data/habilitation/services/cahiers/cahiers.service';
import { forkJoin, of } from 'rxjs';
import { ModeleFacturation } from 'src/app/data/facturation/models/modele-facturation.model';
import { ModeleFacturationService } from 'src/app/data/facturation/services/modele-facturation/modele-facturation.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { remove } from 'lodash';
import { ModeleArticleAnnee } from 'src/app/data/facturation/models/modele-article-annee.model';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';

@Component({
  selector: 'app-edit-article-annee',
  templateUrl: './edit-article-annee.component.html',
  styleUrls: ['./edit-article-annee.component.scss']
})
export class EditArticleAnneeComponent  implements OnInit {

  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;

  @Input() idModeleFacturation: number;
  @Input() public annee: number;
  
  modeleFacturation: ModeleFacturation;
  modelesArticle: ModeleArticle[];
  modelesArticleSelectionne: ModeleArticle[] = [];
  modelesArticleInAnnee: ModeleArticle[];
  modelesArticleNotInAnnee: ModeleArticle[];
  modelesArticleAnnee: ModeleArticleAnnee[] = [];
  public refAnnees: Campagne[] = [];

  title: string;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly fb: FormBuilder,
    private readonly modeleArticleService: ModeleArticleService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly refFacturationService: ReferencesFacturationService,
    private readonly cahiersService: CahiersService,
    private readonly modalsService: AdvBootstrapModalService,    
    private readonly referentielService: ReferentielService,
    private readonly modeleFacturationService: ModeleFacturationService,
  ) {}

  ngOnInit() {
    this.title =  'page.facturation.modele.modals.article-annee.form.title'
    this.formGroup = this.fb.group({});
    this.loadData(this.idModeleFacturation)
  }

  loadData(idModeleFacturation: number) {
    forkJoin(
      isNaN(idModeleFacturation) ? of(new ModeleFacturation()) : this.modeleFacturationService.getModeleFacturationById(SessionContext.get('idOrganisme'), idModeleFacturation),
        this.referentielService.getReferentiel()
    ).pipe(
      this.loaderService.operator()
    ).subscribe(
      ([modeleFacturation, ref]) => {
        this.modeleFacturation = modeleFacturation;
        this.modelesArticle = this.modeleFacturation.modeleArticleList;
        this.filterItemsOfAnnee();
        this.refAnnees = ref.campagnes;
      }
    );
  }

  submit() {
    
    const refCampagne = this.refAnnees.find(refAnnee => refAnnee.annee === this.annee);

    this.modelesArticleInAnnee.forEach(article => {
      
      let modeleArticleAnnee = new ModeleArticleAnnee();;

      modeleArticleAnnee.idModeleArticle = article.id;
      modeleArticleAnnee.idRefCampagne = refCampagne.id;

      this.modelesArticleAnnee.push(modeleArticleAnnee);
    });  

    this.modeleArticleService.modifyModeleArticleAnnee(SessionContext.get('idOrganisme'), this.modelesArticleAnnee, this.idModeleFacturation ).pipe().subscribe(() => {
      this.modal.close(); 
    }      
    );   
       
  }

  cancel() {    
    this.modal.close();
  }

  filterItemsOfAnnee(){    
    this.modelesArticleInAnnee = this.modelesArticle.filter(x => x.campagnes.some(c => c.annee === this.annee) );
    this.modelesArticleNotInAnnee = this.modelesArticle.filter(x=> !x.campagnes.some(c => c.annee === this.annee) );
  }

  selectAllArticle(event: any): void {
    this.modelesArticleSelectionne = [];
    if (event.target.checked) {
      this.modelesArticleNotInAnnee.forEach(article => {
        this.modelesArticleSelectionne.push(article);
      });
    }
  }

  onCheckboxChange(modelesArticle: ModeleArticle): void {
    if (this.modelesArticleSelectionne.includes(modelesArticle)) {
      remove(this.modelesArticleSelectionne, modelesArticle);
    } else {
      this.modelesArticleSelectionne.push(modelesArticle);
    }
  }

  supprimerModelArticle(modelesArticle: ModeleArticle) {
      remove(this.modelesArticleInAnnee, modelesArticle);
      this.modelesArticleNotInAnnee.push(modelesArticle);   
  }

  addModelArticleYear(){
    this.modelesArticleSelectionne.forEach(article => {
      remove(this.modelesArticleNotInAnnee, article);
      this.modelesArticleInAnnee.push(article); 
    });
    this.modelesArticleSelectionne = [];
  }

}

import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormsModule,
} from "@angular/forms";
import { NgbActiveModal, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RechercherPrestataireComponent } from "../rechercher-prestataire/rechercher-prestataire.component";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { TranslateService } from "@ngx-translate/core";
import {
  NavigationContext,
  SessionContext,
} from "src/app/core/services/config/app.settings";
import { Produit } from "src/app/data/habilitation/models/produit.model";
import { TypeVin } from "src/app/data/declaration/models/type-vin.model";
import { UtilisateurTypeCode } from "src/app/data/intervenant/models/enums/type-utilisateur.enum";
import { StockProduit } from "src/app/data/declaration/models/stock-produit.model";
import { OperateurSitesService } from "src/app/data/declaration/services/operateur-sites/operateur-sites.service";
import { StockProduitService } from "src/app/data/declaration/services/stock-produit/stock-produit.service";
import { ReferentielService } from "src/app/data/declaration/services/referentiel/referentiel.service";
import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import { Referentiel } from "src/app/data/declaration/models/referentiel.model";
import { Site } from "src/app/data/declaration/models/site.model";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { TypeCahierCode } from "src/app/data/habilitation/models/enums/type-cahier-code.enum";
import { ProduitsService } from "src/app/data/declaration/services/produits/produits.service";
import { SaisieTirage } from "src/app/data/declaration/models/saisie-tirage.model";
import { Operateur } from "src/app/data/intervenant/models/operateur.model";
import { Assemblage } from "src/app/data/declaration/models/assemblage.model";
import { MouvementsProduitsService } from "src/app/data/declaration/services/mouvements/mouvements-produits.service";
import { ToastrService } from "ngx-toastr";
import * as moment from 'moment';
import { Contenant } from "src/app/data/declaration/models/contenant.model";

@Component({
  selector: "app-saisir-tirage",
  templateUrl: "./saisir-tirage.component.html",
  styleUrls: ["./saisir-tirage.component.scss"],
})
export class SaisirTirageComponent implements OnInit {

  @Input()
  syntheseProduit: Produit;
  @Input()
  campagne: string;
  @Input()
  typeCahier: TypeCahierCode;
  
  public formSaisieTirage: FormGroup;
  
  
  get form_produits() {
    return this.formSaisieTirage.get('produits');
  }

  get numeroTirage() {
    return this.formSaisieTirage.get("numeroTirage");
  }

  get date() {
    return this.formSaisieTirage.get("date");
  }
  get lot() {
    return this.formSaisieTirage.get("lot");
  }
  get volume() {
    return this.formSaisieTirage.get("volume");
  }
  get volumeLiqueur() {
    return this.formSaisieTirage.get("volumeLiqueur");
  }
  get entreposage() {
    return this.formSaisieTirage.get("entreposage");
  }

  //get volume() { return this.formSaisieConditionnement.get('volume'); }

  idOperateur: number;
  loaded = false;
  produits: Produit[];
  
  public referentiel: Referentiel = new Referentiel();
  public sitesOperateur: Site[] = [];
  public stocksProduit: StockProduit[] = [];
  //public syntheseProduit: Produit = new Produit();
  public stockCourant: StockProduit = Object.assign(new StockProduit(), {
    stock: 0,
  });
  public typesVin: TypeVin[] = [];

  get volumeTotalValue() {
    let volumeTotal = 0;

    // Récupérer le volume de l'année en cours
    if (this.volume.value) {
      volumeTotal = this.volume.value;
    }

    // Additionner les volumes des assemblages
    (this.formSaisieTirage.get("assemblages") as FormArray).controls.forEach(
      (assemblage) => {
        const volumeAssemblage = assemblage.get("volume").value;
        if (volumeAssemblage) {
          volumeTotal += volumeAssemblage;
        }
      }
    );
    // additionner volume liqueur
    if (this.volumeLiqueur.value) {
      volumeTotal += this.volumeLiqueur.value;
    }

    return this.bypassFloatingPointPrecision(volumeTotal);
  }

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly operateurSitesService: OperateurSitesService,
    private readonly stockProduitService: StockProduitService,
    private readonly referentielService: ReferentielService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly produitServiceDec: ProduitsService,
    private readonly mouvementsProduitsService: MouvementsProduitsService,
    private readonly toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.idOperateur =
      SessionContext.get("utilisateurType") === UtilisateurTypeCode.OPERATEUR
        ? SessionContext.get("idIntervenant")
        : NavigationContext.get("idOperateur");

    this.formSaisieTirage = this.fb.group({
      //produit: [{ value: this.syntheseProduit.libelle, disabled: true }],
      date: [undefined, Validators.required],
      lot: [undefined, Validators.required],
      numeroTirage: [undefined, Validators.required],
      volume: [
        undefined,
        [
          Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
          this.positifStrictValidator,
          this.volumeCourantValidator.bind(this),
        ],
      ],
      volumeLiqueur: [
        undefined,
        [
          Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
          this.positifStrictValidator,
          this.volumeCourantValidator.bind(this),
        ],
      ],
      assemblages: this.fb.array([]),
      entreposage: [undefined, Validators.required],
      contenants: this.fb.array(
        [],
        Validators.compose([this.contenantsValidator.bind(this)])
      ),
      typeVin: [undefined, Validators.required],
      produits: [undefined, Validators.required],
      prestataire: undefined
    });

    this.formSaisieTirage.get("volume").valueChanges.subscribe(() => {
      const contenants = this.formSaisieTirage.get("contenants") as FormArray;
      contenants.controls.forEach((contenant: FormGroup) =>
        contenant.get("volume").updateValueAndValidity()
      );
    });

    this.loadData();
  }

  close() { this.modal.dismiss(); }

  submit() {
    if (this.formSaisieTirage.valid) {
      const date = this.getField('date').value as NgbDateStruct;

      const saisie = new SaisieTirage();
      saisie.codeProduit = (this.getField('produits').value as Produit).code;
      saisie.codeProduitBase = this.syntheseProduit.code;
      saisie.dateTirage = moment([date.year, date.month - 1, date.day, 0, 0, 0]);
      saisie.numeroTirage = this.getField('numeroTirage').value;
      saisie.numeroLot = this.getField('lot').value;
      saisie.volumeVinBase = this.volume.value;
      saisie.volumeLiqueur = this.volumeLiqueur.value;
      saisie.site = this.getField('entreposage').value;
      saisie.typeVin = this.getField('typeVin').value;

      const prestataire = this.getField('prestataire').value as Operateur;
      if (prestataire) {
        saisie.idPrestataire = prestataire.id;
      }

      // assemblages
      saisie.assemblages = []; //[Object.assign(new Assemblage(), { annee: parseInt(this.campagne, 10), volume: this.getField('volume').value })];
      this.getAssemblages().controls.forEach(assemblage => {
        saisie.assemblages.push(Object.assign(new Assemblage(), {
        annee: assemblage.get('annee').value.annee,
                volume: assemblage.get('volume').value
        }))        
      });
      // Récupérer les contenants
      (this.getField('contenants') as FormArray).controls.forEach(contenant => {
        saisie.contenants.push(Object.assign(new Contenant(), {
          idType: contenant.get('type').value.idContenant,
          nombre: contenant.get('nombre').value,
          volume: contenant.get('volume').value
        }));
      });

      this.mouvementsProduitsService.creerTirage(this.idOperateur, this.campagne, saisie).subscribe(() => {
        this.translate.get('page.declarations.synthese.modal.saisirTirage.creation-ok').subscribe(msg => {
          this.toastr.success('', msg);
        });
        this.modal.close();
      });      
    }
  }

  loadData() {

    if(this.syntheseProduit){
    // Charger les stocks, les contenants, les adresses, les produits liés
    const sources = [
      this.stockProduitService.getStockByProductCodeAndMoveType(
      this.idOperateur,
      this.syntheseProduit.code ,
      "VRAC"
    ),
    this.referentielService.getReferentiel(),
    this.operateurSitesService.getSitesOperateur(this.idOperateur),          
    this.produitServiceDec.getProduitsByProduitOrigine(this.syntheseProduit.id)]    
    forkJoin(sources)
      .pipe(this.loaderService.operator())
      .subscribe(([stocks, ref, operateurs, produitsByProduitOrigine]) => {
        // Affecter les stocks
        stocks.forEach((stock) => {
          if (stock.annee.toString() === this.campagne) {
            this.stockCourant = stock;
          } else {
            this.stocksProduit.push(stock);
          }
        });
        // Affecter les contenants
        this.referentiel = ref;
        // Affecter les adresses
        this.sitesOperateur = operateurs;
        // Récupérer les produits liés au CDC sélectionné
        this.produits = produitsByProduitOrigine;
        this.form_produits.enable();

        this.loaded = true;        
      });
    }


  }

  rechercherPrestataire() {
    const modal = this.modalService.open(RechercherPrestataireComponent, {
      backdrop: "static",
      size: "lg",
    });
    modal.componentInstance.idOperateur = this.idOperateur;

    modal.result.then(
      (acheteur) => {
        this.formSaisieTirage.get("prestataire").setValue(acheteur);
      },
      () => {}
    );
  }

  get raisonSocialeAcheteur() {
    if (this.formSaisieTirage.get('prestataire').value) {
        return this.formSaisieTirage.get('prestataire').value.raisonSociale || this.formSaisieTirage.get('prestataire').value.email;
    }
    return '';
  }

  getContenants() {
    return (this.formSaisieTirage.get("contenants") as FormArray).controls;
  }

  addContenant(type: string) {
    const control = this.formSaisieTirage.get(type) as FormArray;
    control.push(this.init(type));
  }

  volumeLteStockValidatorFactory(stockType: "courant" | "assemblage") {
    return (control: FormControl) => {
      let stock = null;
      try {
        if (stockType === "courant") {
          stock = this.stockCourant.stock;
        } else {
          stock = control.parent.get("annee").value.stock;
        }
      } catch (e) {
        stock = 0;
      }
      return /*this.typeCahier !== 'IGP' &&*/ control.value > stock
        ? { invalid: true }
        : null;
    };
  }

  assemblageVolumeValidator(control: FormControl) {
    let result = null;

    if (control.value && control.parent) {
      result =
        control.value > control.parent.get("annee").value.stock
          ? { invalid: true }
          : null;
    }

    return result;
  }

  getField(key: string) {
    return this.formSaisieTirage.get(key);
  }

  volumeCourantValidator(control: FormControl) {
    return control.value > this.stockCourant.stock ? { invalid: true } : null;
  }
  contenantsValidator() {
    let result = null;
    let volumes = 0;
    let volumeTotal = 0;
    const arrayVolumes: AbstractControl[] = [];

    if (this.formSaisieTirage) {
      const form = this.formSaisieTirage.get("contenants") as FormArray;
      volumeTotal = this.volumeTotalValue;
      form.controls.forEach((control) => {
        volumes += +control.value.volume;
        arrayVolumes.push(control);
      });
    }

    if (volumes > volumeTotal) {
      result = { invalid: true };
      arrayVolumes.forEach((control) => {
        const errors = control.get("volume").errors || {};
        errors.incorrect = true;
        control.get("volume").setErrors(errors);
      });
    } else {
      arrayVolumes.forEach((control) => {
        let errors = control.get("volume").errors || {};
        delete errors.incorrect;
        if (Object.keys(errors).length === 0) {
          errors = null;
        }
        control.get("volume").setErrors(errors);
      });
    }

    return result;
  }

  remove(type: string, index: number) {
    const control = this.formSaisieTirage.get(type) as FormArray;
    control.removeAt(index);
  }

  init(type: string): FormGroup {
    let form: FormGroup = null;

    if (type === "assemblages") {
      form = this.fb.group({
        annee: [
          undefined,
          [this.selectedYearsOrContenantValidator(type, "annee").bind(this)],
        ],
        volume: [
          undefined,
          [
            Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
            this.positifStrictValidator,
            this.assemblageVolumeValidator,
            Validators.required,
          ],
        ],
      });
    } else if (type === "contenants") {
      form = this.fb.group({
        type: [
          undefined,
          [this.selectedYearsOrContenantValidator(type, "type").bind(this)],
        ],
        nombre: [
          undefined,
          [Validators.pattern(/^[0-9]\d*$/), this.positifStrictValidator],
        ],
        volume: [
          undefined,
          [
            Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
            this.positifStrictValidator,
            this.contenantValidator.bind(this),
            Validators.required,
          ],
        ],
      });

      // Calculer automatiquement le volume lorsque l'on modifie le nombre
      form
        .get("nombre")
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => this.calculerVolumeContenant(value, form));

      // Calculer automatiquement le nombre lorsque l'on modifie le volume
      form
        .get("volume")
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value) => this.calculerNombreContenant(value, form));
    }

    return form;
  }

  selectedYearsOrContenantValidator(
    formulaire: string,
    champ: string
  ): ValidatorFn {
    return (control: FormControl): ValidatorFn | any => {
      const value = control.value;
      const controls = (this.formSaisieTirage.get(formulaire) as FormArray)
        .controls;

      // Renvoyer invalid si le même contenant a été sélectionné deux fois
      let trouve = 0;
      let cpt = 0;
      while (trouve < 2 && cpt < controls.length) {
        trouve += controls[cpt++].get(champ).value == value ? 1 : 0;
      }

      return trouve >= 2 ? { invalid: true } : null;
    };
  }

  calculerVolumeContenant(value: number, form: FormGroup) {
    value = Math.floor(value);
    form.patchValue({ nombre: value });

    if (value > 0) {
      const type = form.get("type").value;
      if (type) {
        form.patchValue({
          volume: this.bypassFloatingPointPrecision(
            type.volumeContenant * value
          ),
        });
      }
    }
  }

  calculerNombreContenant(value: number, form: FormGroup) {
    if (value > 0) {
      const type = form.get("type").value;
      if (type) {
        const nombre = Math.floor(
          this.bypassFloatingPointPrecision(value / type.volumeContenant)
        );

        form.patchValue({
          nombre: nombre.toString(),
          volume: this.bypassFloatingPointPrecision(
            nombre * type.volumeContenant
          ),
        });
      }
    }
  }

  positifStrictValidator(control: FormControl) {
    const value = parseFloat(control.value);

    if (isNaN(value) && control.value) {
      return { invalid: true };
    }
    return control.value > 0 ? null : { invalid: true };
  }

  contenantValidator(control: FormControl) {
    return control.value > this.volumeTotalValue ? { invalid: true } : null;
  }

  private bypassFloatingPointPrecision(nombre: number): number {
    return Math.round(nombre * 1000) / 1000;
  }

  createFormAssemblage() {
    return this.fb.group({
      annee: [undefined, [this.uniqueValueInFormArrayValidatorFactory(this.getAssemblages(), "annee"), Validators.required]],
      volume: [undefined, [
          Validators.pattern(/(^\d+$)|(^\d+\.\d{1,2}$)|(^\d+\,\d{1,2}$)/),
          this.positifStrictValidator,
          this.volumeLteStockValidatorFactory("assemblage"),
          Validators.required,
        ],
      ],
    });
  }

  getAssemblages() {
    return this.formSaisieTirage.get("assemblages") as FormArray;
  }
  getAssemblage(index: number) {
    return this.formSaisieTirage.get('assemblages.' + index) as FormGroup;
  }

  addAssemblage() {
    this.getAssemblages().push(this.createFormAssemblage());
  }

  removeAssemblage(index: number) {
    this.getAssemblages().removeAt(index);
  }

  uniqueValueInFormArrayValidatorFactory(
    formArray: FormArray,
    fieldKey: string
  ) {
    return (control: FormControl) => {
      formArray.controls.forEach((group: FormGroup) => {
        const ct = group.get(fieldKey);
        if (ct !== control && ct.value === control.value) {
          return { invalid: true };
        }
      });

      return null;
    };
  }

  onTypeChange(i: number) {
    (this.formSaisieTirage.get(`contenants.${i}`) as FormControl)
      .patchValue({ nombre: '', volume: '' });
  }
}
